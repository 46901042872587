import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ForumRoutingModule } from './forum-routing-module';
import { ForumsComponent } from './forums/forums.component';

import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { ChipsModule } from 'primeng/chips';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { PageHeaderComponent } from 'src/app/components/page-header/page-header.component';
import { ForumPostsComponent } from './forum-posts/forum-posts.component';
import { DialogService } from 'primeng/dynamicdialog';
import { LikeComponent } from './components/like/like.component';
import { UpvoteComponent } from './components/upvote/upvote.component';
import { TopicPostComponent } from './components/topic-post/topic-post.component';
import { TopicPostReplyComponent } from './components/topic-post-reply/topic-post-reply.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldWithLabelComponent } from 'src/app/components/field-with-label/field-with-label.component';
import { DropdownModule } from 'primeng/dropdown';
import { AddTopicComponent } from './components/add-topic/add-topic.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { EditPostReplyComponent } from './components/edit-post-reply/edit-post-reply.component';
import { EditForumPostComponent } from './components/edit-forum-post/edit-forum-post.component';

@NgModule({
	declarations: [
		ForumsComponent,
		ForumPostsComponent,
		LikeComponent,
		UpvoteComponent,
		TopicPostComponent,
		TopicPostReplyComponent,
		AddTopicComponent,
		EditPostReplyComponent,
		EditForumPostComponent,
	],
	imports: [
		CommonModule,
		RouterModule.forChild(ForumRoutingModule),
		TableModule,
		TooltipModule,
		ChipsModule,
		PipesModule,
		PageHeaderComponent,
		ProgressSpinnerModule,
		FormsModule,
		ReactiveFormsModule,
		FieldWithLabelComponent,
		DropdownModule,
		CKEditorModule,
	],
	providers: [DialogService],
})
export class ForumModule {}
