export class Api {
	static SIGN_IN = '/supplier/sign-in';
	static REST_PASSWORD = '/supplier/reset-password';
	static NEW_PASSWORD = `/supplier/new-password`;
	static GET_BRANDS = '/brand';
	static CATEGORIES = '/categories';

	static GET_SUPPLIER_DETAILS = `/supplier/details`;
	static SUPPLIER_DETAILS_UPDATE = `/supplier/update-details`;
	static PRODUCTS = '/supplier/products';
	static PRODUCT_DETAILS = `/product`;
	static ORDERS = '/supplier/orders';
	static SALES = '/supplier/sales';
	static FORUM_CATEGORIES = '/supplier/forum-categories';
	static FORUM = '/supplier/forum';

	static GET_FORUM_CATEGORIES = '/forum/forum-categories';
	static TOPIC_DETAILS = '/forum/topic-details';
	static TOPIC = '/forum/topic';
	static TOPIC_LIKE = `/forum/topic-like`;
	static TOPIC_UNLIKE = `/forum/topic-unlike`;

	static TOPIC_POST = `/forum/post`;
	static POST_UPVOTE = `/forum/post-upvote`;
	static POST_UPVOTE_REMOVE = `/forum/post-upvote-remove`;
	static POST_LIKE = `/forum/post-like`;
	static POST_UNLIKE = `/forum/post-like-remove`;

	static POST_REPLY = `/forum/post-reply`;
	static REPLY_LIKE = `/forum/reply-like`;
	static REPLY_UNLIKE = `/forum/reply-like-remove`;
	static REPLY_UPVOTE = `/forum/reply-upvote`;
	static REPLY_UPVOTE_REMOVE = `/forum/reply-upvote-remove`;

	static POST_IMAGE_UPLOAD = `/forum/post-image`;
	static PRODUCT = `/product`;

	// static ADD_PRODUCT = '/product/add-product';
	// static UPDATE_PRODUCT = (id: string) => `/product/${id}`;
	// static DELETE_PRODUCT = '/product/';
	// static DELETE_PRODUCT_VARIANT = '/product/remove-product-variant/';
	// static DELETE_PRODUCT_IMAGE = (id: string) => `/product/remove-product-image/${id}`;

	static STATISTICS = '/supplier/statistics';
	static GET_ORDER_DETAILS = (id: string) => `/orders/details/${id}`;
	static REMOVE_IMAGE = `/supplier/remove-image`;
	static PREVIEW = `/supplier/preview`;
}
