<app-loader *ngIf="isLoading"></app-loader>
<main>
	<div class="side-menu-wrapper">
		<app-side-menu></app-side-menu>
	</div>
	<div class="wrapper">
		<app-header></app-header>
		<div class="page-wrapper">
			<router-outlet></router-outlet>
		</div>
	</div>
</main>