import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { HttpService } from 'src/app/services/http/http.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
// import { CkEditorImageUploadAdapter } from 'src/app/services/CkEditor-image-upload.adapter';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { UserService } from 'src/app/services/user/user.service';
import { Api } from 'src/app/const/api';
import { CkEditorImageUploadAdapter } from 'src/app/services/CkEditor-image-upload.adapter';
import { EditForumPostComponent } from '../components/edit-forum-post/edit-forum-post.component';
import { AddTopicComponent } from '../components/add-topic/add-topic.component';
import { EditPostReplyComponent } from '../components/edit-post-reply/edit-post-reply.component';
import { ForumType } from '../enum/forum-type.enum';
import { CreateForumTopicDialogType } from 'src/app/enums/create-topic-dialog-type.enum';
import { ForumLinkTo } from '../enum/forum-link-to.enum';
// import { AddTopicComponent } from '../add-topic/add-topic.component';
// import { EditForumPostComponent } from '../edit-forum-post/edit-forum-post.component';
// import { EditPostReplyComponent } from '../edit-post-reply/edit-post-reply.component';

@Component({
	selector: 'app-forum-posts',
	templateUrl: './forum-posts.component.html',
	styleUrls: ['./forum-posts.component.scss'],
})
export class ForumPostsComponent implements OnInit {
	topicId: string = '';
	topicData: any;
	topicPosts: any;
	editor = ClassicEditor;
	editorConfig = {
		placeholder: 'Type your reply here...',
		// plugins: ['MediaEmbed'],
		toolbar: {
			items: [
				'undo',
				'redo',
				'|',
				'bold',
				'italic',
				'fontsize',
				'|',
				'uploadImage',
				'|',
				'mediaEmbed',
				'link',
			],
		},
		mediaEmbed: {
			previewsInData: true,
		},
		link: {
			addTargetToExternalLinks: true,
		},
	};
	isModerator: boolean = true;
	isLogIn: boolean = true;
	canCreateForum: boolean = true;
	constructor(
		private route: ActivatedRoute,
		private http: HttpService,
		private utility: UtilityService,
		private location: Location,
		private confirmationService: ConfirmationService,
		public dialogService: DialogService,
		public userService: UserService,
	) {
		this.route.paramMap.subscribe((param: any) => {
			this.topicId = param.params['id'];
			this.getTopicDetails();
			this.getPosts();
		});
	}

	ngOnInit(): void {
		// this.isLogIn = this.userService.isLogIn;
		// this.checkForumAccess();
	}

	getTopicDetails() {
		this.utility.showLoading();
		this.http.get(`${Api.TOPIC_DETAILS}/${this.topicId}`).subscribe({
			next: (res: any) => {
				console.log(res);

				this.topicData = res;
				this.utility.hideLoading();
				this.checkModerationAccess();
			},
			error: (err) => {
				console.log(err);
			},
		});
	}

	getPosts() {
		this.http.get(`${Api.TOPIC}/${this.topicId}`).subscribe({
			next: (res: any) => {
				this.topicPosts = res;
			},
			error: (err) => {
				console.log(err);
			},
		});
	}

	replyOnTopic(topicId: string, content: any) {
		const params = {
			forum_id: topicId,
			post_content: content,
		};
		this.http.post(Api.TOPIC_POST, params).subscribe({
			next: (res: any) => {
				this.topicData.enableReply = false;
				this.topicData.reply = '';
				this.topicPosts.unshift(res);
			},
			error: (err) => {
				console.log(err);
			},
		});
	}

	likeTopic(topicId: string) {
		this.topicData.likes++;
		this.topicData.liked = true;
		const params = {
			forum_id: topicId,
		};
		this.http.post(Api.TOPIC_LIKE, params).subscribe({
			next: (res: any) => { },
			error: (err) => {
				console.log(err);
			},
		});
	}

	unlikeTopic(topicId: string) {
		const params = {
			forum_id: topicId,
		};
		this.http.post(Api.TOPIC_UNLIKE, params).subscribe({
			next: (res: any) => {
				if (this.topicData.likes && this.topicData.likes > 0) {
					this.topicData.likes--;
					this.topicData.liked = false;
				}
			},
			error: (err) => {
				console.log(err);
			},
		});
	}

	likePost = (postId: string, index: number) => {
		this.topicPosts[index].likes++;
		this.topicPosts[index].liked = true;
		const params = {
			liked_on_id: postId,
			forum_id: this.topicId,
		};
		this.http.post(Api.POST_LIKE, params).subscribe({
			next: (res: any) => { },
			error: (err) => {
				console.log(err);
			},
		});
	};

	unlikePost = (postId: string, index: number) => {
		if (this.topicPosts[index].likes > 0) {
			this.topicPosts[index].likes--;
			this.topicPosts[index].liked = false;
		}
		const params = {
			liked_on_id: postId,
			forum_id: this.topicId,
		};
		this.http.post(Api.POST_UNLIKE, params).subscribe({
			next: (res: any) => { },
			error: (err) => {
				console.log(err);
			},
		});
	};

	replyOnPost = (postId: string, reply: any, data: any, index: number) => {
		const params = {
			forum_id: this.topicId,
			parent: postId,
			reply_content: reply,
		};
		this.http.post(Api.POST_REPLY, params).subscribe({
			next: (res: any) => {
				this.topicPosts[index].enableReply = false;
				this.topicPosts[index].reply = '';
				// this.topicPosts.unshift(res);
				this.showReplies(index);
				this.loadPostReplies(this.topicPosts[index].id, index);
			},
			error: (err) => {
				console.log(err);
			},
		});
	};

	back() {
		this.location.back();
	}

	showReplies = (index: number) => {
		this.topicPosts[index].repliesExpanded = true;
		this.topicPosts[index].replyLoading = true;
		if (this.topicPosts[index].replies) {
			this.topicPosts[index].replyLoading = false;
			return;
		} else {
			this.loadPostReplies(this.topicPosts[index].id, index);
		}
	};

	private loadPostReplies(postId: string, postIndex: number) {
		this.http.get(`${Api.POST_REPLY}/${postId}`).subscribe({
			next: (res: any) => {
				this.topicPosts[postIndex].replyLoading = false;
				this.topicPosts[postIndex].replies = res;
				this.topicPosts[postIndex].replies_count = res.length;
			},
			error: (err) => {
				console.log(err);
			},
		});
	}

	hideReplies = (index: number) => {
		this.topicPosts[index].repliesExpanded = false;
	};

	onImageError(event: any, name: string) {
		event.target.src = `https://ui-avatars.com/api/?background=random&name=${name}`;
	}

	upvotePost = (postId: string, index: number) => {
		this.topicPosts[index].upvote_count++;
		this.topicPosts[index].upvote = true;
		const params = {
			upvote_on_id: postId,
			forum_id: this.topicId,
		};
		this.http.post(Api.POST_UPVOTE, params).subscribe({
			next: (res: any) => { },
			error: (err) => {
				console.log(err);
			},
		});
	};

	removeUpvote = (postId: string, index: number) => {
		if (this.topicPosts[index].upvote_count > 0) {
			this.topicPosts[index].upvote_count--;
			this.topicPosts[index].upvote = false;
		}
		const params = {
			upvote_on_id: postId,
			forum_id: this.topicId,
		};
		this.http.post(Api.POST_UPVOTE_REMOVE, params).subscribe({
			next: (res: any) => { },
			error: (err) => {
				console.log(err);
			},
		});
	};

	toggleReply(topicData: any) {
		topicData.reply = '';
		topicData.enableReply = !topicData.enableReply;
	}

	onReady(event: any) {
		event.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
			return new CkEditorImageUploadAdapter(loader);
		};
	}

	checkModerationAccess() {
		// this.http.get(Api.MODERATION_ACCESS(this.topicData.forum_category_id)).subscribe({
		// 	next: (res: any) => {
		// 		if (res.length > 0) {
		// 			this.isModerator = res[0].access == 1 ? true : false;
		// 		}
		// 	},
		// 	error: (err) => {
		// 		console.log(err);
		// 	},
		// });
	}

	editTopicPost = (postData: any, index: number) => {
		const dialogRef = this.dialogService.open(EditForumPostComponent, {
			header: `Edit Post`,
			// width: '60%',
			styleClass: 'w-[90%] md:w-[60%]',
			contentStyle: { overflow: 'auto' },
			baseZIndex: 10000,
			data: {
				postData: postData,
				topicId: this.topicId,
			},
		});
		dialogRef.onClose.subscribe((data: any) => {
			if (data) {
				this.topicPosts[index].post_content = data;
			}
		});
	};

	removeTopicPost = (postData: any, index: number) => {
		this.confirmationService.confirm({
			header: 'Delete Post?',
			message: 'Do you want to remove this post? All data related to this post will be removed permanently.',
			icon: 'pi pi-info-circle',
			accept: () => {
				this._processPostDeletion(postData.id, index);
			},
			reject: () => { },
		});
	};

	private _processPostDeletion(postId: string, index: number) {
		this.utility.showLoading();
		this.http.delete(`${Api.TOPIC_POST}/${postId}`).subscribe({
			next: (res: any) => {
				this.topicPosts.splice(index, 1);
				this.utility.hideLoading();
				this.utility.showSuccessToast(res.message);
			},
			error: (err) => {
				this.utility.hideLoading();
				this.utility.showErrorToast(err.error.message);
			},
		});
	}

	checkForumAccess() {
		// this.http.get(Api.CAN_CREATE).subscribe({
		// 	next: (res: any) => {
		// 		if (this.isLogIn && res.data) {
		// 			this.canCreateForum = true;
		// 		} else {
		// 			this.canCreateForum = false;
		// 		}
		// 	},
		// 	error: (err) => {
		// 		console.log(err);
		// 	},
		// });
	}

	editTopic = (topicData: any) => {
		const dialogRef = this.dialogService.open(AddTopicComponent, {
			header: 'Edit Topic',
			// width: '60%',
			styleClass: 'w-[90%] md:w-[60%]',
			contentStyle: { overflow: 'auto' },
			baseZIndex: 10000,
			data: {
				topicData: this.topicData,
			},
		});
		dialogRef.onClose.subscribe((data: any) => {
			if (data) {
				this.getTopicDetails();
			}
		});
	};

	removeTopic(topicData: any) {
		this.confirmationService.confirm({
			header: 'Delete Topic?',
			message:
				'Do you want to remove this topic? All data related to this topic will be removed permanently.',
			icon: 'pi pi-info-circle',
			accept: () => {
				this._processTopicDeletion(topicData.id);
			},
			reject: () => { },
		});
	}

	private _processTopicDeletion(topicId: string) {
		this.utility.showLoading();
		this.http.delete(`${Api.TOPIC}/${topicId}`).subscribe({
			next: (res: any) => {
				console.log(res);

				this.utility.hideLoading();
				this.utility.showSuccessToast(res.message);
				this.back();
			},
			error: (err) => {
				this.back();
				this.utility.hideLoading();
				this.utility.showErrorToast(err.error.message);
			},
		});
	}

	editPostReply = (postReplyData: any, postIndex: number, replyIndex: number) => {
		const dialogRef = this.dialogService.open(EditPostReplyComponent, {
			header: `Edit Post Reply`,
			styleClass: 'w-[90%] md:w-[60%]',
			contentStyle: { overflow: 'auto' },
			baseZIndex: 10000,
			data: {
				postReplyData: postReplyData,
				postId: this.topicPosts[postIndex].id,
			},
		});
		dialogRef.onClose.subscribe((data: any) => {
			if (data) {
				this.topicPosts[postIndex].replies[replyIndex].reply_content = data;
			}
		});
	};

	removePostReply = (postReplyData: any, parentIndex: number, replyIndex: number) => {
		this.confirmationService.confirm({
			header: 'Delete Reply?',
			message:
				'Do you want to remove this reply? All data related to this reply will be removed permanently.',
			icon: 'pi pi-info-circle',
			accept: () => {
				this._processPostReplyDeletion(postReplyData.id, parentIndex, replyIndex);
			},
			reject: () => { },
		});
	};

	private _processPostReplyDeletion(replyId: string, parentIndex: number, replyIndex: number) {
		this.utility.showLoading();
		this.http
			.delete(`${Api.POST_REPLY}/${this.topicPosts[parentIndex].id}/${replyId}`)
			.subscribe({
				next: (res: any) => {
					this.topicPosts[parentIndex].replies.splice(replyIndex, 1);
					this.loadPostReplies(this.topicPosts[parentIndex].id, parentIndex);
					this.utility.hideLoading();
					this.utility.showSuccessToast(res.message);
				},
				error: (err) => {
					this.utility.hideLoading();
					this.utility.showErrorToast(err.error.message);
				},
			});
	}

	likeReply = (replyData: any, parentIndex: number, replyIndex: number) => {
		this.topicPosts[parentIndex].replies[replyIndex].likes++;
		this.topicPosts[parentIndex].replies[replyIndex].liked = true;
		const params = {
			liked_on_id: replyData.id,
			forum_id: this.topicId,
		};
		this.http.post(Api.REPLY_LIKE, params).subscribe({
			next: (res: any) => { },
			error: (err) => {
				console.log(err);
			},
		});
	};

	unLikeReply = (replyData: any, parentIndex: number, replyIndex: number) => {
		if (this.topicPosts[parentIndex].replies[replyIndex].likes > 0) {
			this.topicPosts[parentIndex].replies[replyIndex].likes--;
			this.topicPosts[parentIndex].replies[replyIndex].liked = false;
		}
		const params = {
			liked_on_id: replyData.id,
			forum_id: this.topicId,
		};
		this.http.post(Api.REPLY_UNLIKE, params).subscribe({
			next: (res: any) => { },
			error: (err) => {
				console.log(err);
			},
		});
	};
	upvoteReply = (replyData: any, parentIndex: number, replyIndex: number) => {
		this.topicPosts[parentIndex].replies[replyIndex].upvote_count++;
		this.topicPosts[parentIndex].replies[replyIndex].upvote = true;
		const params = {
			upvote_on_id: replyData.id,
			forum_id: this.topicId,
		};
		this.http.post(Api.REPLY_UPVOTE, params).subscribe({
			next: (res: any) => { },
			error: (err) => {
				console.log(err);
			},
		});
	};

	removeReplyUpvote = (replyData: any, parentIndex: number, replyIndex: number) => {
		if (this.topicPosts[parentIndex].replies[replyIndex].upvote_count > 0) {
			this.topicPosts[parentIndex].replies[replyIndex].upvote_count--;
			this.topicPosts[parentIndex].replies[replyIndex].upvote = false;
		}
		const params = {
			upvote_on_id: replyData.id,
			forum_id: this.topicId,
		};
		this.http.post(Api.REPLY_UPVOTE_REMOVE, params).subscribe({
			next: (res: any) => { },
			error: (err) => {
				console.log(err);
			},
		});
	};
}
