import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { PageRoutingModule } from './page-routing.module';
import { RouterModule } from '@angular/router';
import { PageComponent } from './page.component';
import { HomeModule } from './home/home.module';
import { ProductModule } from './product/product.module';
import { SettingsModule } from './settings/settings.module';
import { OrderModule } from './order/order.module';
import { ForumModule } from './forum/forum.module';
import { SalesModule } from './sales/sales.module';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { HeaderComponent } from '../components/header/header.component';
import { SideMenuComponent } from '../components/side-menu/side-menu.component';
import { LoaderComponent } from '../components/loader/loader.component';

@NgModule({
	declarations: [PageComponent],
	imports: [
		CommonModule,
		RouterModule.forChild(PageRoutingModule),
		// HomeModule,
		ProductModule,
		SettingsModule,
		OrderModule,
		ForumModule,
		SalesModule,
		ReactiveFormsModule,
		InputTextModule,
		ButtonModule,
		HeaderComponent,
		SideMenuComponent,
		LoaderComponent,
	],
})
export class PageModule {}
