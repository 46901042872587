import { Component, OnInit } from '@angular/core';
import { Api } from 'src/app/const/api';
import { HttpService } from 'src/app/services/http/http.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { Router } from '@angular/router';
import { AddTopicComponent } from '../components/add-topic/add-topic.component';
import { DialogService } from 'primeng/dynamicdialog';
import { CreateForumTopicDialogType } from 'src/app/enums/create-topic-dialog-type.enum';
import { ForumType } from '../enum/forum-type.enum';
enum SupplierForumType {
	All = 'All',
	General = 'General',
	GeneralDiscussion = 'GeneralDiscussion',
	Product = 'Product',
}

@Component({
	selector: 'app-forums',
	templateUrl: './forums.component.html',
	styleUrls: ['./forums.component.scss'],
})
export class ForumsComponent implements OnInit {
	forumTopics: any[] = [];
	forumCategories: any = [];
	selectedCategory = '';
	products: any = [];
	supplierForumType: SupplierForumType = SupplierForumType.All;
	productId: string = '';
	constructor(
		private utilityService: UtilityService,
		private http: HttpService,
		private router: Router,
		public dialogService: DialogService,
	) { }

	get SupplierForumType() {
		return SupplierForumType;
	}
	ngOnInit(): void {
		this.getForumTopics();
		this.getForumCategories();
		this.getProducts();
	}

	getForumCategories() {
		this.http.get(Api.FORUM_CATEGORIES).subscribe({
			next: (res: any) => {
				this.forumCategories = res;
			},
			error: (err) => {
				console.log(err);
			},
		});
	}

	onCategoryChange(categoryId: any) {
		this.selectedCategory = categoryId;
		this.getForumTopics();
	}

	getForumTopics() {
		let apiEndpoint = `${Api.FORUM}?category=${this.selectedCategory}&type=${this.supplierForumType}`;
		if (this.productId) {
			apiEndpoint += `&product=${this.productId}`;
		}
		this.http.get(apiEndpoint).subscribe({
			next: (res: any) => {
				this.forumTopics = res;
			},
			error: (err) => {
				console.log(err);
			},
		});
	}

	viewTopic(topic: any) {
		this.router.navigate(['/forum', topic.id]);
	}

	addNewTopic() {
		const dialogRef = this.dialogService.open(AddTopicComponent, {
			header: 'Add New Topic',
			// width: '60%',
			styleClass: 'w-[90%] md:w-[60%]',
			contentStyle: { overflow: 'auto' },
			baseZIndex: 10000,
			data: {
				// topicDialogType: CreateForumTopicDialogType.General,
				forumType: ForumType.General
			},
		});

		dialogRef.onClose.subscribe((data: any) => {
			if (data) {
				this.getForumTopics();
			}
		});
	}

	addNewSupplierTopic() {
		const dialogRef = this.dialogService.open(AddTopicComponent, {
			header: 'Add New Topic',
			// width: '60%',
			styleClass: 'w-[90%] md:w-[60%]',
			contentStyle: { overflow: 'auto' },
			baseZIndex: 10000,
			data: {
				// topicDialogType: CreateForumTopicDialogType.Supplier,
				forumType: ForumType.Supplier
			},
		});

		dialogRef.onClose.subscribe((data: any) => {
			if (data) {
				this.getForumTopics();
			}
		});
	}

	getProducts() {
		this.utilityService.showLoading();
		this.http.get(`${Api.PRODUCTS}`).subscribe({
			next: (res: any) => {
				this.products = res;
			},
			error: (err) => {
				this.utilityService.hideLoading();
				console.log(err);
			},
			complete: () => {
				this.utilityService.hideLoading();
			},
		});
	}

	onForumTypeClick(type: SupplierForumType) {
		this.supplierForumType = type;
		this.productId = '';
		this.getForumTopics();
	}

	onProductClick(productId: string) {
		this.productId = productId;
		this.getForumTopics();
	}
}
