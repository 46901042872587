<div class="pt-4">
	<form [formGroup]="editReplyForm" (ngSubmit)="savePost()">
		<div class="flex flex-col">
			<ckeditor [editor]="editor" [config]="editorConfig" formControlName="reply_content"
				placeholder="Reply Content" class="input-style w-full !p-0 h-full" (ready)="onReady($event)">
			</ckeditor>

			<span *ngIf="replyContent?.invalid && (replyContent?.dirty || replyContent?.touched)">
				<span *ngIf="replyContent?.errors?.['required']">
					* Reply Content is required
				</span>
			</span>
		</div>
		<div class="flex justify-end">
			<button type="submit"
				class="w-full rounded-md px-1 py-2 mt-6 bg-secondary text-white hover:bg-secondary-light hover:transition-all">
				Submit
			</button>
		</div>
	</form>
</div>