<div class="page">
	<app-page-header header="Orders">
		<button mat-flat-button color="primary" matTooltip="Add new category" (click)="exportOrders()">
			<span class="material-symbols-rounded"> download </span>
			Export As CSV
		</button>
	</app-page-header>

	<div class="space-y-4">
		<div class="p-4 rounded shadow-md border border-[#c4c4c4]">
			<p class="font-semibold text-lg mb-4">Filters</p>
			<div class="flex gap-1">
				<input type="text" styleClass="w-[450px]" pInputText [(ngModel)]="filterParams.customer_name"
					placeholder="Customer Name" />

				<p-calendar [(ngModel)]="filterParams.date_range" selectionMode="range" [readonlyInput]="true"
					placeholder="Enter a date range"></p-calendar>

				<button type="button" class="w-auto" label="Apply" pButton (click)="getOrders()"
					[disabled]="!filterParams.customer_name && !filterParams.date_range"></button>
				<button type="button" class="w-auto p-button-danger" label="Clear" pButton (click)="clearFilters()"
					[disabled]="!filterParams.customer_name && !filterParams.date_range" severity="danger">
				</button>
			</div>
		</div>

		<div class="">
			<app-simple-table [columns]="columns" [data]="orders" [paginator]="true" [showFilter]="false"
				[showActions]="true" [showEditAction]="false" [showDeleteAction]="false" [onRowClick]="orderDetails">
			</app-simple-table>
		</div>
	</div>
</div>