import { Component, OnInit } from '@angular/core';
import {
	ReactiveFormsModule,
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { DividerModule } from 'primeng/divider';
import { Api } from 'src/app/const/api';
import { HttpService } from 'src/app/services/http/http.service';
import { UserService } from 'src/app/services/user/user.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { CommonModule } from '@angular/common';

@Component({
	standalone: true,
	imports: [
		CommonModule,
		ReactiveFormsModule,
		ButtonModule,
		InputTextModule,
		RouterModule,
		DividerModule,
	],
	selector: 'app-sign-in',
	templateUrl: './sign-in.component.html',
	styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
	signInFrom: UntypedFormGroup;

	constructor(
		private utility: UtilityService,
		private http: HttpService,
		private router: Router,
		public userService: UserService,
	) {}

	ngOnInit(): void {
		this.signInFrom = new UntypedFormGroup({
			email: new UntypedFormControl('', [Validators.required, Validators.email]),
			password: new UntypedFormControl('', [Validators.required]),
		});
	}

	get email() {
		return this.signInFrom.get('email');
	}
	get password() {
		return this.signInFrom.get('password');
	}

	signIn() {
		if (this.signInFrom.valid) {
			this.utility.showLoading();
			let params = {
				email: this.signInFrom.value.email,
				password: this.signInFrom.value.password,
			};
			this.http.post(Api.SIGN_IN, params).subscribe({
				next: async (res: any) => {
					this.userService.setAccessToken(res.token);
					await this.getUserData();
					this.utility.hideLoading();
					this.router.navigate(['/home']);
				},
				error: ({ error }) => {
					this.utility.hideLoading();
					this.utility.showErrorToast(error.message);
				},
				complete: () => {
					this.utility.hideLoading();
				},
			});
		} else {
			this.utility.showErrorToast('Invalid email or password. Please check your details.');
		}
	}

	async getUserData() {
		return new Promise((resolve, reject) => {
			this.http.get(Api.GET_SUPPLIER_DETAILS).subscribe({
				next: (res: any) => {
					this.userService.user = res;
					this.userService.setUserData(res);
					resolve(true);
				},
				error: (err: any) => {
					resolve(false);
				},
			});
		});
	}
}
