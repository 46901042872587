import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { DividerModule } from 'primeng/divider';
import { Api } from 'src/app/const/api';
import { HttpService } from 'src/app/services/http/http.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { CommonModule } from '@angular/common';
import { Pages } from 'src/app/enums/pages.enum';

@Component({
	selector: 'app-forget-password',
	standalone: true,
	imports: [
		CommonModule,
		ReactiveFormsModule,
		ButtonModule,
		InputTextModule,
		DividerModule,
		RouterModule,
	],
	templateUrl: './forget-password.component.html',
	styleUrl: './forget-password.component.scss',
})
export class ForgetPasswordComponent {
	form: FormGroup;

	constructor(
		private utilityService: UtilityService,
		private http: HttpService,
		private router: Router,
	) {}

	ngOnInit(): void {
		this.form = new FormGroup({
			email: new FormControl('', [Validators.required, Validators.email]),
		});
	}

	get email() {
		return this.form.get('email');
	}

	submitForm() {
		if (this.form.valid) {
			this.utilityService.showLoading();
			this.http.post(Api.REST_PASSWORD, { email: this.form.value.email }).subscribe({
				next: (res: any) => {
					this.utilityService.hideLoading();
					this.utilityService.showSuccessToast(res.data);
					this.router.navigate([Pages.Home]);
				},
				error: (err: any) => {
					this.utilityService.hideLoading();
					this.utilityService.showErrorToast(err.error.message);
				},
			});
		} else {
			this.form.markAllAsTouched();
			this.utilityService.showErrorToast('Invalid email');
		}
	}
}
