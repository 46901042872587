<div class="pt-4">
	<form [formGroup]="topicForm" (ngSubmit)="saveTopic()">
		<div class="flex flex-col lg:flex-row gap-4">
			<span class="flex-1">
				<app-field-with-label title="Title" [required]="true">
					<input type="text" placeholder="Your awesome topic title..." class="input-style" pInputText
						   formControlName="topic_title" />
					<span *ngIf="topicTitle?.invalid && (topicTitle?.dirty || topicTitle?.touched)">
						<span *ngIf="topicTitle?.errors?.['required']">
							*Topic title is required
						</span>
					</span>
				</app-field-with-label>
			</span>

			<span class="flex-1" *ngIf="topicDialogType == createForumTopicDialogType.General">
				<app-field-with-label title="General Forum" [required]="false">
					<p-dropdown [options]="forumCategories" formControlName="forum_category_id"
								optionLabel="forum_category_name" optionValue="id" styleClass="input-style py-0" appendTo="body"
								placeholder="Select General Forum"></p-dropdown>
					<!-- <span *ngIf="topicCategory?.invalid && (topicCategory?.dirty || topicCategory?.touched)">
						<span *ngIf="topicCategory?.errors?.['required']">
							* Topic Forum is required
						</span>
					</span> -->
				</app-field-with-label>
			</span>

			<span class="flex-1" *ngIf="topicDialogType == createForumTopicDialogType.Supplier && supplierProducts && supplierProducts.length > 0">
				<app-field-with-label title="Breeder Forum" [required]="false">
					<p-dropdown [options]="supplierProducts" formControlName="product" optionLabel="product_name"
								optionValue="id" styleClass="input-style !w-full py-0" appendTo="body"
								placeholder="Select Breeder Forum"></p-dropdown>
				</app-field-with-label>
			</span>
		</div>
		<div class="flex flex-col lg:flex-row gap-4 mb-4">
			<span class="flex-1 w-full">
				<app-field-with-label title="Tags" [required]="true">
					<p-chips separator="," [allowDuplicate]="false" [addOnTab]="true" [addOnBlur]="true" [max]="10"
							 formControlName="topic_tags" styleClass="w-full" inputStyleClass="input-style py-0"
							 placeholder="Add multiple tags separated by comma ','"></p-chips>
					<span class="italic font-medium">
						Enter tag and click enter.
					</span>
					<span *ngIf="topicTags?.invalid && (topicTags?.dirty || topicTags?.touched)">
						<span *ngIf="topicTags?.errors?.['required']">
							* Topic Tags is required
						</span>
					</span>
				</app-field-with-label>
			</span>
			<span class="flex-1"></span>
			<!-- <span class="flex-1"></span> -->
		</div>
		<div class="flex">
			<app-field-with-label title="Description" [required]="true" class="w-full">
				<ckeditor [editor]="editor" [config]="editorConfig" formControlName="topic_description"
						  placeholder="Topic Description" class="input-style w-full !p-0 h-full" (ready)="onReady($event)">
				</ckeditor>

				<span *ngIf="topicDescription?.invalid && (topicDescription?.dirty || topicDescription?.touched)">
					<span *ngIf="topicDescription?.errors?.['required']">
						* Topic Description is required
					</span>
				</span>
			</app-field-with-label>
		</div>

		<div class="text-base font-medium mt-2">
			By Clicking Submit you agree to Our, <a href="/assets/Legal_Disclaimer_Uncle_Sam_Seed_Co_Online_Forum.pdf"
			   target="_blank" rel="noreferrer" class="underline font-bold">Legal Disclaimer</a>,
			<a href="/assets/privacy-policy-SSSC.pdf" target="_blank" rel="noreferrer" class="underline font-bold">
				Privacy Policy</a>
			and
			<a href="/assets/terms-and-conditions-SSSC.pdf" target="_blank" rel="noreferrer"
			   class="underline font-bold">Terms of Service</a>

		</div>

		<div class="flex justify-end">
			<button type="submit"
					class="w-full rounded-md px-1 py-2 mt-6 bg-secondary text-white hover:bg-secondary-light hover:transition-all">
				Submit
			</button>
		</div>
	</form>
</div>