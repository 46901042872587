import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SalesComponent } from './sales/sales.component';
import { RouterModule } from '@angular/router';
import { SalesRoutingModule } from './sales-routing.module';
import { FormsModule } from '@angular/forms';
import { OrderRoutingModule } from '../order/order-routing.module';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { ButtonModule } from 'primeng/button';
import { SimpleTableComponent } from 'src/app/components/simple-table/simple-table.component';
import { PageHeaderComponent } from 'src/app/components/page-header/page-header.component';

@NgModule({
	declarations: [SalesComponent],
	imports: [
		CommonModule,
		RouterModule.forChild(SalesRoutingModule),
		CommonModule,
		RouterModule.forChild(OrderRoutingModule),
		FormsModule,
		ButtonModule,
		CalendarModule,
		DropdownModule,
		PageHeaderComponent,
		SimpleTableComponent,
		ButtonModule,
	],
})
export class SalesModule {}
