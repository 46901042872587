<div class="side-menu">
	<div class="branding">
		<img src="/assets/images/logo.jpg" alt="Logo" />
	</div>

	<div class="menu-items">
		<!-- <a class="menu-item" routerLink="/home" [routerLinkActive]="'active'">
			<span class="material-symbols-rounded"> dashboard </span>
			<span>Dashboard</span>
		</a> -->
		<a class="menu-item" routerLink="/forum" [routerLinkActive]="'active'">
			<span class="material-symbols-rounded">
				forum
			</span>
			<span>Forum</span>
		</a>
		<a class="menu-item" routerLink="/products" [routerLinkActive]="'active'">
			<span class="material-symbols-rounded">spa</span>
			<span>Products</span>
		</a>

		<a class="menu-item" routerLink="/orders" [routerLinkActive]="'active'">
			<span class="material-symbols-rounded"> shopping_basket </span>
			<span>Orders</span>
		</a>

		<a class="menu-item" routerLink="/sales" [routerLinkActive]="'active'">
			<span class="material-symbols-rounded"> show_chart </span>
			<span>Sales</span>
		</a>

		<a class="menu-item" routerLink="/settings" [routerLinkActive]="'active'">
			<span class="material-symbols-rounded">settings</span>
			<span>Account & Settings</span>
		</a>



		<button class="menu-item" (click)="signOut()">
			<span class="material-symbols-rounded">power_settings_new</span>
			<span>Sign Out</span>
		</button>
	</div>
</div>