<div class="page">
	<app-page-header header="Your Details"></app-page-header>
	<div class="add-product-container" *ngIf="supplierData">
		<h2 class="text-xl font-semibold pt-2">Company Details</h2>
		<div class="grid grid-cols-2 gap-3 border-b border-gray-300 py-2">
			<div>
				<div class="detail-fields">
					<p>Company name:</p>
					<p>{{ supplierData.company_name }}</p>
				</div>
			</div>
		</div>

		<div class="border-b border-gray-300 py-2">
			<div class="flex">
				<div class="w-full border-r border-gray-300 pr-12">
					<div class="flex items-center justify-between">
						<h2 class="text-xl font-semibold pt-2 pb-1">Your Details</h2>
					</div>
					<div class="detail-fields">
						<p class="w-[120px]">Name:</p>
						<p>{{ supplierData.supplier_name }}</p>
					</div>

					<div class="detail-fields">
						<p class="w-[120px]">Email:</p>
						<p>
							<a href="mailto:{{ supplierData.supplier_email }}">
								{{ supplierData.supplier_email }}
							</a>
						</p>
					</div>

					<div class="detail-fields">
						<p class="w-[120px]">Phone:</p>
						<p>
							<a href="tel:{{ supplierData.phone_country }}{{
									supplierData.supplier_phone
								}}">{{ supplierData.supplier_phone }}</a>
						</p>
					</div>

					<div class="detail-fields">
						<p class="w-[120px]">Registered On:</p>
						<p>{{ supplierData.created_at | date : 'medium' }}</p>
					</div>
				</div>

				<div class="w-full pl-8">
					<div class="flex items-center justify-between">
						<h2 class="text-xl font-semibold pt-2 pb-1">Address</h2>
					</div>

					<div class="detail-fields">
						<p class="w-[120px]">Address 1:</p>
						<p>{{ supplierData.address_1 }}</p>
					</div>

					<div class="detail-fields">
						<p class="w-[120px]">Address-2:</p>
						<p>{{ supplierData.address_2 }}</p>
					</div>

					<div class="detail-fields">
						<p class="w-[120px]">City:</p>
						<p>{{ supplierData.city }}</p>
					</div>

					<div class="detail-fields">
						<p class="w-[120px]">State:</p>
						<p>{{ supplierData.state }}</p>
					</div>

					<div class="detail-fields">
						<p class="w-[120px]">Country:</p>
						<p>{{ supplierData.country }}</p>
					</div>

					<div class="detail-fields">
						<p class="w-[120px]">landmark:</p>
						<p>{{ supplierData.landmark }}</p>
					</div>
				</div>
			</div>
		</div>

		<div class="border-b border-gray-300 py-2 space-y-6">
			<form [formGroup]="form" (ngSubmit)="saveSupplierDetails()">
				<div class="flex justify-between items-center">
					<div class="flex-1 flex flex-col gap-4">
						<div class="flex flex-col">
							<h2 class="text-xl font-semibold">Website Settings</h2>
							<span class="text-sm">Below details will be visible to customers on the website
								shopfront.</span>
						</div>
						<div>
							<p class="text-xl font-semibold">Description: </p>
							<span class="text-sm">
								Pls add 80-120 words that best describe your company, its ethos and products.
							</span>
						</div>
					</div>

					<div class="flex-1">
						<h2 class="text-xl font-semibold">Your Status is:
							<span class="text-red-600" [ngClass]="{'!text-blue-500': supplierData.status == 'A'}">
								{{supplierData.status == 'A' ? 'Active' : 'Inactive'}}
							</span>
						</h2>
						<span class="text-sm" *ngIf="supplierData.status != 'A'">
							This means we are waiting for some more information from you. You can make and view changes
							to your shopfront but it is
							not live yet. Please contact us on <a href="mailto:admin@starsandstripeseed.com"
								class="font-semibold">admin&#64;starsandstripeseed.com</a> to begin
							the onboarding
							process.
						</span>
						<span class="text-sm" *ngIf="supplierData.status == 'A'">
							Your shopfront is now live. You can make changes to your shopfront such as change company
							description, poster and banner
							images.
						</span>
					</div>
				</div>

				<div class="w-full mt-8">
					<ckeditor [editor]="editor" [config]="editorConfig" formControlName="supplierDescription"
						class="w-full !p-0 h-full">
					</ckeditor>
				</div>

				<div class="flex flex-col gap-2 mt-8">
					<div>
						<p class="text-xl font-semibold">Poster: </p>
						<span class="text-sm">
							Square poster for search results summary. Size 1000 x 1000 pixels.
						</span>
					</div>
					<div class="form-field-container mt-4">
						<div class="image-container">
							<div class="selected-image-container" *ngIf="supplierPosterPreview"
								(click)="mainImage.click()">
								<button type="button" pButton class="remove-button" pTooltip="Remove"
									(click)="$event.stopPropagation(); removeSupplierLogo()">
									<span class="material-symbols-rounded">
										close
									</span>
								</button>
								<img [src]="supplierPosterPreview" alt="Your Poster" />
							</div>

							<img src="/assets/images/add.png" alt="Select poster your page" class="add-image"
								(click)="mainImage.click()" pTooltip="Select poster for your page"
								*ngIf="!supplierPosterPreview" />

							<input type="file" class="file-input" #mainImage (change)="onSupplierLogoSelect($event)"
								accept="image/png, image/jpeg" />
						</div>
					</div>
				</div>

				<div class="flex flex-col gap-2 mt-8">
					<div>
						<p class="text-xl font-semibold">Banner: </p>
						<span class="text-sm">
							<!-- Rectangular banner required for shopfront size 1900 x 300 pixels. -->
							Rectangular banner required for shopfront size 1900 x 300 pixels. If the banner is shorter,
							the image appears larger.
							Please don't use large image files as these take longer to load. Please use 'Publish' to
							check your work and click
							'Save' only once you are ready to publish.
						</span>
					</div>
					<div class="form-field-container mt-4">
						<div class="image-container">
							<div class="selected-image-container" *ngIf="supplierBannerPreview">
								<button type="button" pButton class="remove-button" pTooltip="Remove selected image"
									(click)="$event.stopPropagation(); removeSupplierCover()" severity="danger"
									[rounded]="true">
									<span class="material-symbols-rounded">
										close
									</span>
								</button>
								<img [src]="supplierBannerPreview" alt="Select Banner image for your page" />
							</div>
							<img src="/assets/images/add.png" alt="Select banner image for your page" class="add-image"
								(click)="bannerImage.click()" pTooltip="Select banner image for your page"
								*ngIf="!supplierBannerPreview" />
							<input type="file" class="file-input" #bannerImage (change)="onSupplierCoverSelect($event)"
								accept="image/png, image/jpeg" multiple />
						</div>
					</div>
				</div>
				<div class="form-field justify-end mt-4 gap-4">
					<p-button type="button" label="Preview" (click)="previewData()"
						[disabled]="!selectedSupplierBanner || !supplierDescription.value"
						pTooltip="Preview Data Before Save"></p-button>
					<p-button type="submit" label="Save"></p-button>
				</div>
			</form>
		</div>
	</div>
</div>