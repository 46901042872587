<div class="px-2 py-2 flex gap-2 md:gap-4 rounded" [ngClass]="{'bg-blue-50': (index % 2 == 0)}">
	<img [src]="data.profile_picture" [alt]="data.user_full_name" class="rounded-full h-5 w-5 sm:h-10 sm:w-10"
		onError="this.src = '/assets/images/placeholder-image.webp'">
	<div class="flex flex-col gap-2 w-full">
		<div class="flex justify-between">
			<p class="text-sm italic font-medium">
				by {{
				(data.user_name)
				? data.user_name
				: data.user_full_name
				}}
			</p>
			<span class="text-sm">{{data.created_at | date: "MMM dd, yyyy 'at' hh:mm a"}}</span>
		</div>
		<p class="text-sm" [innerHTML]="data.post_content"></p>

		<div class="flex justify-between">
			<div class="flex gap-2">
				<button class="post-button font-semibold"
					(click)="data.repliesExpanded ? hideReplies(index) : showReplies(index)"
					*ngIf="data.replies_count > 0">
					<span>{{data.replies_count}} {{data.replies_count > 1 ? 'Replies' : 'Reply'}}</span>
					<span class="material-symbols-rounded text-red-500 text-[20px]">
						{{data.repliesExpanded ? 'expand_less' : 'expand_more'}}
					</span>
				</button>


				<app-upvote [upvotes]="data.upvote_count" [isUpvoted]="data.upvote == '1'"
					(click)="data.upvote == '1'? onRemoveUpvote(data.id, index) : onUpvote(data.id, index)">
				</app-upvote>

				<app-like [likes]="data.likes" [isLiked]="data.liked == '1'"
					(click)="data.liked == '1'? onUnlike(data.id, index) : onLike(data.id, index)">
				</app-like>

				<button class="post-button" (click)="toggleReply(data)" *ngIf="canCreateForum">
					<span class="material-symbols-rounded text-[20px]">reply</span>
					<span>Reply</span>
				</button>
			</div>
			<div class="flex gap-2" *ngIf="isModerator && !data.is_tester">
				<button class="edit-button" (click)="editPost(data, index)">
					<span class="material-symbols-rounded text-[20px]">edit</span>
				</button>
				<button class="delete-button" (click)="removePost(data, index)">
					<span class="material-symbols-rounded text-[20px]">delete</span>
				</button>
			</div>
		</div>

		<div class="flex flex-col pt-4 border-t border-[#d4d4d4]" *ngIf="data.repliesExpanded">
			<div *ngIf="data.replyLoading" class="w-full flex justify-center">
				<p-progressSpinner strokeWidth="4" styleClass="w-6 h-6"></p-progressSpinner>
			</div>

			<ng-container *ngIf="!data.replyLoading">
				<div class="flex flex-col">
					<app-topic-post-reply *ngFor="let reply of data.replies; let j = index" [data]="reply"
						[parentIndex]="index" [index]="j" [isModerator]="isModerator" [editReply]="editReply"
						[removeReply]="removeReply" [likeReply]="likeReply" [unLikeReply]="unLikeReply"
						[upvoteReply]="upvoteReply" [removeReplyUpvote]="removeReplyUpvote"></app-topic-post-reply>
				</div>
			</ng-container>
		</div>


		<div *ngIf="data.enableReply" class="flex flex-col gap-2">
			<ckeditor [editor]="editor" [config]="editorConfig" [(ngModel)]="data.reply" placeholder="Your reply"
				class="input-style w-full !p-0 h-full" (ready)="onReady($event)"></ckeditor>
			<button type="submit"
				class="w-fit rounded-md px-2 py-2 bg-secondary text-white hover:bg-secondary-light hover:transition-all self-end"
				(click)="reply(data.id, data.reply, data, index)">
				Submit
			</button>
		</div>
	</div>
</div>