<div class="page">
	<app-page-header header="Order details for order #{{ orderData?.display_id }}">
	</app-page-header>
	<div class="add-product-container" *ngIf="orderData">
		<div class="border-b border-gray-300 py-2">
			<h2 class="text-xl font-semibold pt-2">Order Details</h2>
			<div class="mt-2">
				<div class="detail-fields">
					<p>Order Subtotal:</p>
					<p>${{ orderData.subtotal }}</p>
				</div>
				<!-- <div class="detail-fields">
					<p>Shipping Charge:</p>
					<p>${{ orderData.shipping_charge }}</p>
				</div> -->
				<div class="detail-fields">
					<p>Order Discount:</p>
					<p>${{ orderData.discount }}</p>
				</div>
				<div class="detail-fields">
					<p>Order Total:</p>
					<p>${{ orderData.total }}</p>
				</div>
				<div class="detail-fields">
					<p>Order Status:</p>
					<p class="bg-yellow-400 p-2 rounded-full text-white max-w-min">
						{{ orderData.status }}
					</p>
				</div>
			</div>
		</div>

		<div class="py-2">
			<div class="flex">
				<div class="w-full">
					<div class="flex items-center justify-between">
						<h2 class="text-xl font-semibold pt-2 pb-1">Products For Sale</h2>
					</div>

					<app-simple-table [columns]="columns" [data]="orderItems" [paginator]="false" [showFilter]="false"
						[showActions]="true" [showEditAction]="false" [showDeleteAction]="false"
						[rows]="orderItems.length">
					</app-simple-table>
				</div>
			</div>
		</div>
	</div>
</div>