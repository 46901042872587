import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Api } from 'src/app/const/api';
import { FieldsType } from 'src/app/enums/fields-type.enum';
import { Pages } from 'src/app/enums/pages.enum';
import { CommonApiCallService } from 'src/app/services/common-api-call/common-api-call.service';
import { HttpService } from 'src/app/services/http/http.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-products',
	templateUrl: './products.component.html',
	styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit {
	imageUrl: string = environment.imageUrl;
	products: any[] = [];
	categories: any = [];
	suppliers: any = [];
	filterParams = {
		category_id: '',
	};

	columns = [
		{
			field: 'product_main_image',
			header: 'Product Image',
			disableSort: false,
			fieldType: FieldsType.Image,
			imageBaseUrl: this.imageUrl,
			isRequired: false,
			prefix: '',
			suffix: '',
			isEditable: true,
		},
		{
			field: 'product_name',
			header: 'Product Name',
			disableSort: true,
			fieldType: FieldsType.Text,
			isRequired: true,
		},
		{
			field: 'product_sku',
			header: 'SKU',
			disableSort: true,
			fieldType: FieldsType.Text,
			isRequired: true,
		},
		{
			field: 'category_name',
			header: 'Category Name',
			disableSort: false,
			fieldType: FieldsType.Text,
			isRequired: true,
		},
		{
			field: 'status',
			header: 'Status',
			disableSort: false,
			fieldType: FieldsType.Boolean,
			isRequired: false,
			isEditable: false,
			trueValue: 'Active',
			falseValue: 'Inactive',
		},
	];

	constructor(
		private utilityService: UtilityService,
		private http: HttpService,
		private commonApiCall: CommonApiCallService,
		private router: Router,
	) {}

	ngOnInit(): void {
		this.getProducts();
		this.getCategories();
	}

	getProducts() {
		this.utilityService.showLoading();
		this.http.get(`${Api.PRODUCTS}?category=${this.filterParams.category_id}`).subscribe({
			next: (res: any) => {
				this.products = res;
			},
			error: (err) => {
				this.utilityService.hideLoading();
				console.log(err);
			},
			complete: () => {
				this.utilityService.hideLoading();
			},
		});
	}

	getCategories() {
		this.commonApiCall.getCategories().subscribe({
			next: (res) => {
				this.categories = res;
				this.categories.unshift({
					id: '',
					category_name: 'Show All',
				});
			},
			error: (err) => {
				console.log(err);
			},
			complete: () => {
				this.utilityService.hideLoading();
			},
		});
	}

	getProductDetails = (data: any) => {
		this.router.navigate([Pages.Products, data.id]);
	};

	// updateProductStatus(event: MatSlideToggleChange, productId: string) {
	// 	let params = { status: event.checked ? '1' : '0' };
	// 	this.commonApiCall.updateProduct(productId, params).subscribe({
	// 		next: (res: any) => {
	// 			this.utilityService.showSnackBar('Product status updated');
	// 		},
	// 		error: (err: any) => {
	// 			console.log(err);
	// 		},
	// 	});
	// }

	// deleteProduct(productId: string, index: number) {
	// 	this.http.delete(Api.DELETE_PRODUCT + productId).subscribe({
	// 		next: (res: any) => {
	// 			this.products.data.splice(index, 1);
	// 			this.products._updateChangeSubscription();
	// 			this.utilityService.showSnackBar('Product deleted.');
	// 		},
	// 		error: (err: any) => {
	// 			this.utilityService.showSnackBar('Unable to delete product');
	// 		},
	// 	});
	// }

	clearFilter() {
		this.filterParams.category_id = '';
		this.getProducts();
	}
}
