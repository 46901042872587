import { Component, Input, OnInit } from '@angular/core';

@Component({
	standalone: true,
	selector: 'app-page-header',
	templateUrl: './page-header.component.html',
	styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent {
	@Input('header') header: string = 'string';
}
