import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Api } from 'src/app/const/api';
import { CkEditorImageUploadAdapter } from 'src/app/services/CkEditor-image-upload.adapter';
import { HttpService } from 'src/app/services/http/http.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
	selector: 'app-edit-post-reply',
	templateUrl: './edit-post-reply.component.html',
	styleUrls: ['./edit-post-reply.component.scss'],
})
export class EditPostReplyComponent {
	editReplyForm: FormGroup;
	editor = ClassicEditor;
	editorConfig: any = {
		placeholder: 'Write something about your topic...',
		// plugins: ['MediaEmbed'],
		toolbar: {
			items: [
				'undo',
				'redo',
				'|',
				'bold',
				'italic',
				'fontsize',
				'|',
				'uploadImage',
				'|',
				'mediaEmbed',
				'link',
			],
		},
		mediaEmbed: {
			previewsInData: true,
		},
		link: {
			addTargetToExternalLinks: true,
		},
	};
	postId: string = '';
	postReplyData: any;

	constructor(
		private ref: DynamicDialogRef,
		private dialogConfig: DynamicDialogConfig,
		private http: HttpService,
		private utilityService: UtilityService,
	) {
		const { postReplyData, postId } = this.dialogConfig.data;
		this.postId = postId;
		this.postReplyData = postReplyData;
		this.editReplyForm = new FormGroup({
			reply_content: new FormControl('', [Validators.required]),
		});

		if (postReplyData) {
			this.editReplyForm.patchValue({
				reply_content: postReplyData.reply_content,
			});
		}
	}

	get replyContent() {
		return this.editReplyForm.get('reply_content');
	}

	savePost() {
		if (this.editReplyForm.valid) {
			const params = {
				parent: this.postId,
				reply_content: this.editReplyForm.value.reply_content,
			};

			this.http
				.patch(`${Api.POST_REPLY}/${this.postId}/${this.postReplyData.id}`, params)
				.subscribe({
					next: (res: any) => {
						this.ref.close(this.editReplyForm.value.reply_content);
						this.utilityService.showSuccessToast('Reply updated.');
					},
					error: (err: any) => {
						console.log(err);
						this.utilityService.showErrorToast('Unable to update reply.');
					},
				});
		} else {
			this.utilityService.showErrorToast('Required field is invalid.');
		}
	}

	onReady(event: any) {
		event.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
			return new CkEditorImageUploadAdapter(loader);
		};
	}
}
