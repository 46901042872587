import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrdersComponent } from './orders/orders.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { OrderRoutingModule } from './order-routing.module';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { SimpleTableComponent } from 'src/app/components/simple-table/simple-table.component';
import { CalendarModule } from 'primeng/calendar';
import { ButtonModule } from 'primeng/button';
import { PageHeaderComponent } from 'src/app/components/page-header/page-header.component';
import { InputTextModule } from 'primeng/inputtext';

export const MY_FORMATS = {
	parse: {
		dateInput: 'DD/MM/YYYY',
	},
	display: {
		dateInput: 'LL',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};

@NgModule({
	declarations: [OrdersComponent, OrderDetailsComponent],
	imports: [
		CommonModule,
		RouterModule.forChild(OrderRoutingModule),
		FormsModule,
		SimpleTableComponent,
		CalendarModule,
		ButtonModule,
		PageHeaderComponent,
		InputTextModule,
	],
})
export class OrderModule {}
