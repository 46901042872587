export enum FieldsType {
	Image = 'Image',
	Action = 'Action',
	Text = 'text',
	LinkButton = 'linkButton',
	Number = 'number',
	Email = 'email',
	Telephone = 'tel',
	DropDown = 'dropdown',
	DropDownWithImage = 'dropDownWithImage',
	AutoComplete = 'autoComplete',
	Date = 'date',
	Boolean = 'boolean',
}
