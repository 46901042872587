<div class="page">
	<app-page-header header="Sales">
		<!-- <button mat-flat-button color="primary" matTooltip="Add new category" >
			<span class="material-symbols-rounded"> download </span>
		</button> -->
		<p-button label="Export As CSV" (click)="exportOrders()"></p-button>
	</app-page-header>

	<div class="space-y-4">
		<div class="p-4 rounded shadow-md border border-[#c4c4c4]">
			<p class="font-semibold text-lg mb-4">Filters</p>
			<div class="flex gap-1">
				<p-dropdown [options]="categories" [(ngModel)]="filterParams.category_id" optionLabel="category_name"
					optionValue="id" [showClear]="true" placeholder="Select a Category"></p-dropdown>

				<p-calendar [(ngModel)]="filterParams.date_range" selectionMode="range" [readonlyInput]="true"
					placeholder="Enter a date range"></p-calendar>

				<button type="button" class="w-auto" label="Apply" pButton (click)="getSales()"
					[disabled]="!filterParams.category_id && !filterParams.date_range"></button>
				<button type="button" class="w-auto p-button-danger" label="Clear" pButton (click)="clearFilters()"
					[disabled]="!filterParams.category_id && !filterParams.date_range" severity="danger">
				</button>
			</div>
		</div>

		<div class="">
			<app-simple-table [columns]="columns" [data]="sales" [paginator]="true" [showFilter]="false"
				[showActions]="false" [showEditAction]="false" [showDeleteAction]="false" [onRowClick]="orderDetails">
			</app-simple-table>
		</div>
	</div>
</div>