import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DaysAgoPipe } from './days-ago/days-ago.pipe';
import { CommaSplitterPipe } from './comma-splitter/comma-splitter.pipe';
import { RemoveCharactersPipe } from './remove-characters/remove-characters.pipe';

@NgModule({
  declarations: [DaysAgoPipe, CommaSplitterPipe, RemoveCharactersPipe],
  imports: [CommonModule],
  exports: [DaysAgoPipe, CommaSplitterPipe, RemoveCharactersPipe],
})
export class PipesModule {}
