import { Injectable } from '@angular/core';
import { ProgressEventService } from '../progress-event/progress-event.service';
import * as dayjs from 'dayjs';
import { MessageService } from 'primeng/api';

@Injectable({
	providedIn: 'root',
})
export class UtilityService {
	constructor(
		private messageService: MessageService,
		private progressEvent: ProgressEventService,
	) {}

	showSuccessToast(message: string, duration?: number) {
		this.messageService.add({
			severity: 'success',
			summary: 'Success',
			detail: message,
			life: duration ? duration : 4000,
		});
	}
	showErrorToast(message: string, duration?: number) {
		this.messageService.add({
			severity: 'error',
			summary: 'Error',
			detail: message,
			life: duration ? duration : 4000,
		});
	}

	showLoading() {
		this.progressEvent.loader.emit(true);
	}

	hideLoading() {
		this.progressEvent.loader.emit(false);
	}

	setSession(key: string, value: string) {
		sessionStorage.setItem(key, value);
	}

	getSession(key: string, parse?: boolean) {
		if (parse) {
			return JSON.parse(sessionStorage.getItem(key));
		} else {
			return sessionStorage.getItem(key);
		}
	}

	generateRandomString(length = 12): string {
		let randomString = Math.random().toString(36).substr(2, length).toUpperCase();
		return randomString;
	}

	formatDate(date: Date, format: string): string {
		return dayjs(date).format(format);
	}
}
