import { Component, Input } from '@angular/core';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CkEditorImageUploadAdapter } from 'src/app/services/CkEditor-image-upload.adapter';

@Component({
	selector: 'app-topic-post',
	templateUrl: './topic-post.component.html',
	styleUrls: ['./topic-post.component.scss'],
})
export class TopicPostComponent {
	@Input('data') data: any;
	@Input('onLike') onLike: Function = () => {};
	@Input('onUnlike') onUnlike: Function = () => {};
	@Input('reply') reply: Function = () => {};
	@Input('index') index: any;
	@Input('showReplies') showReplies: Function = () => {};
	@Input('hideReplies') hideReplies: Function = () => {};
	@Input('onUpvote') onUpvote: Function = () => {};
	@Input('onRemoveUpvote') onRemoveUpvote: Function = () => {};

	@Input('editPost') editPost: Function = () => {};
	@Input('removePost') removePost: Function = () => {};
	@Input('isModerator') isModerator: boolean = false;
	@Input('canCreateForum') canCreateForum: boolean = false;

	@Input('editReply') editReply: Function = () => {};
	@Input('removeReply') removeReply: Function = () => {};

	@Input('likeReply') likeReply: Function = () => {};
	@Input('unLikeReply') unLikeReply: Function = () => {};

	@Input('upvoteReply') upvoteReply: Function = () => {};
	@Input('removeReplyUpvote') removeReplyUpvote: Function = () => {};

	editor = ClassicEditor;
	editorConfig = {
		placeholder: 'Type your reply here...',
		// plugins: ['MediaEmbed'],
		toolbar: {
			items: [
				'undo',
				'redo',
				'|',
				'bold',
				'italic',
				'fontsize',
				'|',
				'uploadImage',
				'|',
				'mediaEmbed',
				'link',
			],
		},
		mediaEmbed: {
			previewsInData: true,
		},
		link: {
			addTargetToExternalLinks: true,
		},
	};

	toggleReply(data: any) {
		data.reply = '';
		data.enableReply = !data.enableReply;
	}

	onReady(event: any) {
		event.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
			return new CkEditorImageUploadAdapter(loader);
		};
	}
}
