<div class="px-2 py-2 flex gap-2 md:gap-4 rounded border-b border-[#d4d4d4]">
	<img [src]="data.profile_picture" [alt]="data.user_full_name" class="rounded-full h-5 w-5 sm:h-10 sm:w-10"
		onError="this.src = '/assets/images/placeholder-image.webp'">
	<div class="flex flex-col gap-2 w-full">
		<div class="flex justify-between">
			<p class="text-sm italic font-medium">
				by {{
				(data.user_name)
				? data.user_name
				: data.user_full_name
				}}
			</p>
			<span class="text-sm">{{data.created_at | date: "MMM dd, yyyy 'at' hh:mm a"}}</span>
		</div>
		<p class="text-sm" [innerHTML]="data.reply_content"></p>

		<div class="flex justify-between">
			<div class="flex gap-2">
				<app-upvote [upvotes]="data.upvote_count" [isUpvoted]="data.upvote == '1'"
					(click)="data.upvote == '1'? removeReplyUpvote(data, parentIndex, index) : upvoteReply(data, parentIndex, index)">
				</app-upvote>

				<app-like [likes]="data.likes" [isLiked]="data.liked == '1'"
					(click)="data.liked == '1'? unLikeReply(data, parentIndex, index) : likeReply(data, parentIndex, index)">
				</app-like>

			</div>
			<div class="flex justify-end gap-2" *ngIf="isModerator && !data.is_tester">
				<button class="edit-button" (click)="editReply(data, parentIndex, index)">
					<span class="material-symbols-rounded text-[20px]">edit</span>
				</button>
				<button class="delete-button" (click)="removeReply(data, parentIndex, index)">
					<span class="material-symbols-rounded text-[20px]">delete</span>
				</button>
			</div>
		</div>
	</div>
</div>