<div class="page">
	<app-page-header header="Forum">
		<button type="button"
				class="bg-secondary text-white rounded-md px-3 py-2 flex items-center justify-center text-sm md:mt-0 md:m-0 m-auto mt-4 md:text-left text-center "
				(click)="addNewTopic()">
			<span class="material-symbols-rounded">add</span>
			Create General Forum
		</button>

		<button type="button"
				class="bg-secondary text-white rounded-md px-3 py-2 flex items-center justify-center text-sm md:mt-0 md:m-0 m-auto mt-4 md:text-left text-center "
				(click)="addNewSupplierTopic()">
			<span class="material-symbols-rounded">add</span>
			Create Breeder Forum
		</button>
	</app-page-header>

	<div class="!py-4 border-b border-gray w-full">
		<h1 class="font-semibold text-md mb-4">General Forums</h1>
		<div class="flex gap-2 overflow-auto sm:overflow-hidden">
			<div
				 class="flex gap-4 w-full min-w-max sm:flex-wrap sm:max-w-full sm:min-w-0 overflow-auto sm:overflow-hidden">
				<span class="forum-tag max-w-max min-w-max w-full !bg-[#9cff9c]" (click)="onCategoryChange('')">
					All Forums
				</span>
				<span *ngFor="let cat of forumCategories" class="forum-tag max-w-max min-w-max w-full"
					  [style.background]="cat.forum_category_color" (click)="onCategoryChange(cat.id)">
					{{cat.forum_category_name}}
				</span>
			</div>
		</div>
		<p class="text-[12px] md:text-[14px] !py-4">
			All our forums are moderated, please refer to our forum terms of use
			<a href="/assets/terms-and-conditions-SSSC.pdf" class="font-bold underline"
			   pTooltip="Click here for Terms and Condition" target="_blank" rel=”noreferrer”>
				here
			</a>.
			If you have purchased or registered with an invite code you can use the forums, otherwise you can
			still read content and upvote / like (but must be registered)
		</p>
	</div>

	<!-- <div class="w-full border-b border-gray !py-4"></div> -->

	<div class="!py-4 border-b border-gray w-full">
		<h1 class="font-semibold text-md mb-4">Breeder Forums
			<span class="font-normal text-[12px] md:text-[14px]"> - You have full access to your breeder forum including
				moderator rights.</span>
		</h1>
		<div class="flex gap-2 overflow-auto sm:overflow-hidden">
			<div
				 class="flex gap-4 w-full min-w-max sm:flex-wrap sm:max-w-full sm:min-w-0 overflow-auto sm:overflow-hidden">
				<span class="forum-tag max-w-max min-w-max w-full !bg-[#9cff9c]"
					  (click)="onForumTypeClick(SupplierForumType.All)">
					All
				</span>
				<span class="forum-tag max-w-max min-w-max w-full !bg-[#c4c4c4]"
					  (click)="onForumTypeClick(SupplierForumType.General)">
					General Topics Only
				</span>
				<span class="forum-tag max-w-max min-w-max w-full !bg-[#d9d0e9]"
					  (click)="onForumTypeClick(SupplierForumType.GeneralDiscussion)">
					General Discussion
				</span>
				<span class="forum-tag max-w-max min-w-max w-full !bg-[#bde9ce]"
					  (click)="onForumTypeClick(SupplierForumType.Product)">
					Product Only
				</span>
				<span *ngFor="let product of products" class="forum-tag max-w-max min-w-max w-full !bg-[#d5f3d5]"
					  [style.background]="product.product_name" (click)="onProductClick(product.id)">
					{{product.product_name}}
				</span>
			</div>
		</div>
	</div>

	<div class="pt-4">
		<p-table [value]="forumTopics" styleClass="p-datatable-striped p-datatable-gridlines text-md"
				 [tableStyle]="{'min-width': '60rem'}" sortMode="multiple">
			<ng-template pTemplate="header">
				<tr>
					<th pSortableColumn="topic_title" class="w-[140px] max-w-[140px] md:w-240px md:max-w-[240px]">
						<div class="flex items-center">
							Topic
							<p-sortIcon field="topic_title"></p-sortIcon>
						</div>
					</th>
					<th pSortableColumn="forum" class="w-[168px] max-w-[168px]">
						<div class="flex items-center">
							Forums
							<p-sortIcon field="forum"></p-sortIcon>
						</div>
					</th>
					<th pSortableColumn="views">
						<div class="flex items-center">
							Views
							<p-sortIcon field="views"></p-sortIcon>
						</div>
					</th>
					<th [pSortableColumn]="'replies'">
						<div class="flex items-center">
							Replies
							<p-sortIcon [field]="'replies'"></p-sortIcon>
						</div>
					</th>
					<th pSortableColumn="created_at">
						<div class="flex items-center">
							Created
							<p-sortIcon field="created_at"></p-sortIcon>
						</div>
					</th>
					<th pSortableColumn="last_comment">
						<div class="flex items-center">
							Comments
							<p-sortIcon field="last_comment"></p-sortIcon>
						</div>
					</th>
					<th pSortableColumn="likes">
						<div class="flex items-center">
							Likes
							<p-sortIcon field="likes"></p-sortIcon>
						</div>
					</th>
					<th class="min-w-[100px]">
						<div class="flex items-center">
							Liked By
						</div>
					</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-topic>
				<tr class="text-[12px] md:text-[14px]" (click)="viewTopic(topic)">
					<td
						class="w-[180px] max-w-[180px] md:max-w-[240px] md:w-240px overflow-hidden whitespace-nowrap text-ellipsis">
						{{topic.topic_title}}</td>
					<td>
						<div class="flex gap-1 items-center">
							<!-- <div class="h-[14px] w-[14px]" [style.background]="topic.forum_category_color"></div> -->
							<span class="w-[150px] max-w-[150px] overflow-hidden whitespace-nowrap text-ellipsis">
								{{topic.forum}}
							</span>
						</div>
						<!-- <span class="p-1 rounded block w-full" [style.background]="topic.forum_category_color">
								{{topic.forum_category_name}}
							</span> -->
					</td>
					<td>{{(topic.views && topic.views != 0) ? topic.views : '-' }}</td>
					<td>{{(topic.replies && topic.replies != 0) ? topic.replies : '-' }}</td>
					<td>{{topic.created_at | daysAgo}}</td>
					<td>{{(topic.last_comment) ? (topic.last_comment | daysAgo) : '-' }}</td>
					<td>{{(topic.likes && topic.likes != 0) ? topic.likes : '-' }}</td>
					<td class="min-w-[100px] max-w-[226px] overflow-hidden">
						<div class="relative flex gap-1">
							<ng-container *ngFor="let user of topic.liked_by; let i = index">
								<img [src]="user.profile_image" [alt]="user.user_name"
									 class="h-5 w-5 flex-shrink-0 rounded-full"
									 onError="this.src = '/assets/images/placeholder-image.webp'"
									 [pTooltip]="user.user_name ?? user.name + ' liked this'" *ngIf="user.profile_image">

								<img [src]="'https://ui-avatars.com/api/?background=random&name=' + (user.user_name ?? user.name)"
									 alt="" class="h-5 w-5 flex-shrink-0 rounded-full" *ngIf="!user.profile_image" />
								<!-- <avatar variant="beam" [title]="false" [size]="400" [name]="user.user_name ?? user.name"
										[square]="false" [colors]="['#FFAD08', '#EDD75A', '#73B06F', '#0C8F8F', '#405059']"
										*ngIf="!user.profile_image"></avatar> -->
							</ng-container>
						</div>
					</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
</div>