import { Injectable } from '@angular/core';
import { AppConst } from 'src/app/enums/app-const';
import { UtilityService } from '../utility/utility.service';

@Injectable({
	providedIn: 'root',
})
export class UserService {
	accessToken = '';
	user: any;

	constructor(private utility: UtilityService) {}

	setUserData(user: any) {
		this.utility.setSession(AppConst.USER_DATA, JSON.stringify(user));
		this.user = user;
	}

	setAccessToken(token: string) {
		this.utility.setSession(AppConst.ACCESS_TOKEN, token);
		this.accessToken = token;
	}

	getUserData() {
		this.accessToken = this.utility.getSession(AppConst.ACCESS_TOKEN);
		this.user = JSON.parse(this.utility.getSession(AppConst.USER_DATA));
	}
}
