import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Api } from 'src/app/const/api';
import { HttpService } from 'src/app/services/http/http.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-settings',
	templateUrl: './settings.component.html',
	styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
	supplierData: any;
	imageUrl: string = environment.imageUrl;
	selectedSupplierPoster: any = [];
	supplierPosterPreview: any;

	selectedSupplierBanner: any = [];
	supplierBannerPreview: any;

	form: FormGroup;
	editor = ClassicEditor;
	editorConfig = {
		placeholder: 'Add your description...',
		toolbar: {
			items: ['heading', '|', 'bold', 'italic'],
		},
	};
	constructor(private http: HttpService, private utilityService: UtilityService) {
		this.form = new FormGroup({
			supplierDescription: new FormControl(''),
		});
	}

	get supplierDescription() {
		return this.form.get('supplierDescription');
	}

	ngOnInit(): void {
		this.getSupplierDetails();
	}

	getSupplierDetails() {
		this.utilityService.showLoading();
		this.http.get(Api.GET_SUPPLIER_DETAILS).subscribe({
			next: (res: any) => {
				this.supplierData = res;
				this.form.patchValue({
					supplierDescription: this.supplierData.description,
				});
				this.supplierPosterPreview = this.supplierData.poster;
				this.supplierBannerPreview = this.supplierData.banner;
			},
			error: (err) => {
				console.log(err);
			},
			complete: () => {
				this.utilityService.hideLoading();
			},
		});
	}

	saveSupplierDetails() {
		if (
			this.selectedSupplierPoster ||
			this.selectedSupplierPoster ||
			this.form.value.supplierDescription
		) {
			this.utilityService.showLoading();
			const _formData: any = new FormData();
			if (this.form.value.supplierDescription) {
				_formData.append('description', this.form.value.supplierDescription);
			}

			_formData.append('banner', this.supplierBannerPreview);
			_formData.append('poster', this.supplierPosterPreview);

			if (this.selectedSupplierPoster && this.selectedSupplierPoster.name) {
				_formData.append(
					'poster',
					this.selectedSupplierPoster,
					this.selectedSupplierPoster.name,
				);
			}
			if (this.selectedSupplierBanner && this.selectedSupplierBanner.name) {
				_formData.append(
					'banner',
					this.selectedSupplierBanner,
					this.selectedSupplierBanner.name,
				);
			}

			this.http.patch(Api.SUPPLIER_DETAILS_UPDATE, _formData).subscribe({
				next: (res: any) => {
					this.utilityService.hideLoading();
					this.utilityService.showSuccessToast(res.message);
				},
				error: (err: any) => {
					this.utilityService.hideLoading();
					this.utilityService.showErrorToast('Unable to save details.');
				},
			});
		}
	}

	onSupplierLogoSelect(event) {
		this.selectedSupplierPoster = event.target.files[0];
		if (event.target.files[0]) {
			const reader = new FileReader();
			reader.onload = (_event) => {
				this.supplierPosterPreview = reader.result;
			};
			reader.readAsDataURL(event.target.files[0]);
		}
	}

	removeSupplierLogo() {
		if (this.selectedSupplierPoster) {
			this.supplierPosterPreview = null;
			this.selectedSupplierPoster = null;
		} else {
			this.utilityService.showLoading();
			this.http
				.post(Api.REMOVE_IMAGE, { path: this.supplierPosterPreview, type: 'poster' })
				.subscribe({
					next: (res: any) => {
						this.supplierPosterPreview = null;
						this.selectedSupplierPoster = null;
						this.utilityService.hideLoading();
						this.utilityService.showSuccessToast(res.data);
					},
					error: (err: any) => {
						this.utilityService.hideLoading();
						this.utilityService.showErrorToast('Unable to remove image.');
					},
				});
		}
	}

	onSupplierCoverSelect(event) {
		this.selectedSupplierBanner = event.target.files[0];
		if (event.target.files[0]) {
			const reader = new FileReader();
			reader.onload = (_event) => {
				this.supplierBannerPreview = reader.result;
			};
			reader.readAsDataURL(event.target.files[0]);
		}
	}

	removeSupplierCover() {
		if (this.selectedSupplierBanner) {
			this.selectedSupplierBanner = null;
			this.supplierBannerPreview = null;
		} else {
			this.utilityService.showLoading();
			this.http
				.post(Api.REMOVE_IMAGE, { path: this.supplierBannerPreview, type: 'cover' })
				.subscribe({
					next: (res: any) => {
						this.selectedSupplierBanner = null;
						this.supplierBannerPreview = null;
						this.utilityService.hideLoading();
						this.utilityService.showSuccessToast(res.data);
					},
					error: (err: any) => {
						this.utilityService.hideLoading();
						this.utilityService.showErrorToast('Unable to remove image.');
					},
				});
		}
	}

	async previewData() {
		this.utilityService.showLoading();
		const previewData = {
			supplier_company: this.supplierData.company_name,
			banner: this.supplierBannerPreview,
			description: this.supplierDescription.value,
		};

		this.http.post(Api.PREVIEW, previewData).subscribe({
			next: (res: any) => {
				this.utilityService.hideLoading();
				const previewUrl = `${environment.previewUrl}/supplier-preview?preview_id=${res.data}`;
				window.open(previewUrl, '_blank').focus();
			},
			error: (err: any) => {
				this.utilityService.hideLoading();
				this.utilityService.showErrorToast('Unable to prepare preview.');
			},
		});
	}
}
