import { Component, OnInit, ViewChild } from '@angular/core';
import { Api } from 'src/app/const/api';
import { HttpService } from 'src/app/services/http/http.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import * as dayjs from 'dayjs';
import { ExportService } from 'src/app/services/export/export.service';
import { FieldsType } from 'src/app/enums/fields-type.enum';
import { Router } from '@angular/router';
import { Pages } from 'src/app/enums/pages.enum';
@Component({
	selector: 'app-orders',
	templateUrl: './orders.component.html',
	styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent implements OnInit {
	filterParams = {
		customer_name: '',
		date_range: '',
	};
	columns = [
		{
			field: 'display_id',
			header: 'Id',
			disableSort: false,
			fieldType: FieldsType.Text,
			imageBaseUrl: '',
			prefix: '',
			suffix: '',
			isEditable: true,
		},
		{
			field: 'name',
			header: 'Customer Name',
			disableSort: true,
			fieldType: FieldsType.Text,
		},
		{
			field: 'created_at',
			header: 'Order Date',
			disableSort: true,
			fieldType: FieldsType.Date,
			dateFormat: 'MMM, dd YYYY @ hh:mm a',
		},
		{
			field: 'total',
			header: 'Order Total',
			prefix: '$',
			disableSort: false,
			fieldType: FieldsType.Number,
		},
	];
	orders: any[] = [];

	constructor(
		private utilityService: UtilityService,
		private http: HttpService,
		private exportService: ExportService,
		private router: Router,
	) {}

	ngOnInit(): void {}

	ngAfterViewInit(): void {
		this.getOrders();
	}

	getOrders() {
		const { customer_name, date_range } = this.filterParams;
		const startDateParsed = date_range[0] ? dayjs(date_range[0]).format('YYYY-MM-DD') : '';
		const endDateParsed = date_range[1] ? dayjs(date_range[1]).format('YYYY-MM-DD') : '';
		this.http
			.get(
				`${Api.ORDERS}?customer=${customer_name}&start=${startDateParsed}&end=${endDateParsed}`,
			)
			.subscribe({
				next: (res: any) => {
					this.orders = res;
				},
				error: (err) => {
					console.log(err);
				},
				complete: () => {
					this.utilityService.hideLoading();
				},
			});
	}

	exportOrders() {
		const csvColumns = ['#', 'Consumer Name', 'Order Date', 'Order Time', 'Order Total $'];
		let csvData = [];
		let dataToExport = [];
		dataToExport = this.orders;
		let index = 0;
		for (const data of dataToExport) {
			index++;
			csvData.push({
				'#': index,
				'Consumer Name': data.name,
				'Order Date': dayjs(data.created_at).format('MMMM DD, YYYY'),
				'Order Time': dayjs(data.created_at).format('hh:mm a'),
				'Order Total $': `${data.total}`,
			});
		}
		this.exportService.exportToCsv(
			csvData,
			`orders-${dayjs().format('DD-MM-YYYY')}`,
			csvColumns,
		);
	}

	clearFilters() {
		this.filterParams = {
			customer_name: '',
			date_range: '',
		};
		this.getOrders();
	}

	orderDetails = (orderData: any) => {
		this.router.navigate([Pages.Orders, orderData.id]);
	};
}
