import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Api } from 'src/app/const/api';
import { FieldsType } from 'src/app/enums/fields-type.enum';
import { HttpService } from 'src/app/services/http/http.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-order-details',
	templateUrl: './order-details.component.html',
	styleUrls: ['./order-details.component.scss'],
})
export class OrderDetailsComponent implements OnInit {
	orderId: string;
	orderData: any;

	imageUrl: string = environment.imageUrl;

	orderItems: any[] = [];

	columns = [
		{
			field: 'product_name',
			header: 'Item',
			disableSort: false,
			fieldType: FieldsType.Text,
			imageBaseUrl: '',
			prefix: '',
			suffix: '',
			isEditable: true,
		},
		{
			field: 'product_sku',
			header: 'Product SKU',
			disableSort: true,
			fieldType: FieldsType.Text,
		},
		{
			field: 'variant_name',
			header: 'Seeds per pack',
			disableSort: true,
			fieldType: FieldsType.Text,
		},
		{
			field: 'quantity',
			header: 'Quantity',
			prefix: '',
			disableSort: false,
			fieldType: FieldsType.Text,
		},
		{
			field: 'item_price',
			header: 'Price',
			prefix: '$',
			disableSort: false,
			fieldType: FieldsType.Number,
		},
		{
			field: 'item_discount_price',
			header: 'Discounted Price',
			prefix: '$',
			disableSort: false,
			fieldType: FieldsType.Number,
		},
		{
			field: 'item_weight_g',
			header: 'Weight(G)',
			prefix: '',
			disableSort: false,
			fieldType: FieldsType.Number,
		},
		{
			field: 'item_weight_o',
			header: 'Weight(OZ)',
			prefix: '',
			disableSort: false,
			fieldType: FieldsType.Number,
		},
		{
			field: 'category_name',
			header: 'Category',
			prefix: '',
			disableSort: false,
			fieldType: FieldsType.Text,
		},
		{
			field: 'brand_name',
			header: 'Brand',
			prefix: '',
			disableSort: false,
			fieldType: FieldsType.Text,
		},
	];
	constructor(
		private utilityService: UtilityService,
		private http: HttpService,
		private route: ActivatedRoute,
	) {
		this.route.params.subscribe((params) => {
			this.orderId = params['orderId'];
		});
	}

	ngOnInit(): void {
		this.getOrderDetails();
	}

	getOrderDetails() {
		this.http.get(`${Api.ORDERS}/${this.orderId}`).subscribe({
			next: (res: any) => {
				this.orderData = res;
				this.orderItems = res.order_items;
			},
			error: (err) => {
				console.log(err);
			},
			complete: () => {
				this.utilityService.hideLoading();
			},
		});
	}
}
