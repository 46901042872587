<div class="sign-in-container">
	<div class="form-container">
		<form [formGroup]="form" (ngSubmit)="submitForm()">

			<img class="h-[100px] w-auto mx-auto" src="/assets/images/logo.jpg" alt="Logo">
			<h1 class="my-1 mt-4 text-center">Enter your registered email to reset your password
			</h1>
			<p-divider></p-divider>
			<div class="flex flex-col gap-4">
				<div class="flex flex-col gap-2">
					<label for="email">Email</label>
					<input pInputText id="email" type="email" formControlName="email" required />
					<small class="error" *ngIf="email.invalid && (email.dirty || email?.touched)">
						<span *ngIf="email?.errors?.['required']">* Email is required </span>
						<span *ngIf="email.errors?.['email']">* Email is invalid </span>
					</small>
				</div>
			</div>

			<button type="submit" class="w-full mt-4" label="Submit" pButton></button>
		</form>
	</div>
</div>