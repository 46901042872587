import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-topic-post-reply',
  templateUrl: './topic-post-reply.component.html',
  styleUrls: ['./topic-post-reply.component.scss'],
})
export class TopicPostReplyComponent {
  @Input('data') data: any;
  @Input('parentIndex') parentIndex: any;
  @Input('index') index: any;
  @Input('isModerator') isModerator: any;

  @Input('editReply') editReply: Function = () => {};
  @Input('removeReply') removeReply: Function = () => {};

  @Input('upvoteReply') upvoteReply: Function = () => {};
  @Input('removeReplyUpvote') removeReplyUpvote: Function = () => {};

  @Input('likeReply') likeReply: Function = () => {};
  @Input('unLikeReply') unLikeReply: Function = () => {};
}
