<div class="page" *ngIf="productData">
	<app-page-header [header]="productData?.product_name + ' details'">
	</app-page-header>
	<div class="add-product-container">
		<div class="border-b border-gray-300 py-2">
			<h2 class="text-xl font-semibold pt-2">Product Details</h2>

			<div class="flex gap-2">
				<div class="mt-2 space-y-1 flex-1">
					<div class="detail-fields">
						<p>Name:</p>
						<p>{{ productData.product_name }}</p>
					</div>
					<div class="detail-fields">
						<p>SKU:</p>
						<p>{{ productData.product_sku }}</p>
					</div>
					<div class="detail-fields">
						<p>Category:</p>
						<p>{{ productData.category_name }}</p>
					</div>
					<div class="detail-fields">
						<p>Quality Tested:</p>
						<p>{{ productData.quality_tested == '1' ? 'Yes': 'No' }}</p>
					</div>
					<div class="detail-fields">
						<p>Rating:</p>
						<p>{{ productData.rating }}
							<span class="material-symbols-rounded material-symbols-rounded-filled text-yellow-400">
								star
							</span>
						</p>
					</div>
					<div class="detail-fields">
						<p>Status:</p>
						<p class="bg-yellow-400 p-2 rounded-full text-white max-w-min">
							{{ productData.status == '1' ? 'Active' : 'Inactive' }}
						</p>
					</div>
				</div>
				<div class="flex-1">
					<p-image [src]="imageUrl + productData.product_main_image" [alt]="productData?.product_name"
						[preview]="true" width="250" imageClass="h-[250px] w-[250px] rounded-md"></p-image>
				</div>
			</div>
		</div>

		<div class="border-b border-gray-300 py-2">
			<div class="flex-col">
				<h2 class="text-xl font-semibold pt-2 pb-1">Sort Description</h2>
				<p>
					{{ productData.product_description}}
				</p>
			</div>
		</div>
		<div class="border-b border-gray-300 py-2">
			<div class="flex-col">
				<h2 class="text-xl font-semibold pt-2 pb-1">Long Description</h2>
				<p>
					{{ productData.product_long_description}}
				</p>
			</div>
		</div>

		<div class="w-full py-2">
			<div class="flex items-center justify-between">
				<h2 class="text-xl font-semibold pt-2 pb-1">Products Variants</h2>
			</div>

			<app-simple-table [columns]="columns" [data]="productData.product_variant" [paginator]="false"
				[showFilter]="false" [showActions]="false" [showEditAction]="false" [showDeleteAction]="false"
				[rows]="productData.product_variant.length">
			</app-simple-table>
		</div>

		<div class="border-b border-gray-300 py-2">
			<div class="flex-col">
				<h2 class="text-xl font-semibold pt-2 pb-1">Images</h2>
				<div class="flex gap-4">
					<p-image [src]="imageUrl + img" [alt]="productData?.product_name" [preview]="true" loading="lazy"
						width="150" imageClass="h-[150px] w-[150px] rounded-md object-cover"
						*ngFor="let img of productData.product_images"></p-image>
				</div>
			</div>
		</div>
	</div>
</div>