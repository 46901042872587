<div class="pt-4">
	<form [formGroup]="editPostForm" (ngSubmit)="savePost()">
		<div class="flex flex-col">
			<ckeditor [editor]="editor" [config]="editorConfig" formControlName="post_content"
				placeholder="Post Content" class="input-style w-full !p-0 h-full" (ready)="onReady($event)">
			</ckeditor>

			<span *ngIf="postContent?.invalid && (postContent?.dirty || postContent?.touched)">
				<span *ngIf="postContent?.errors?.['required']">
					* Post Content is required
				</span>
			</span>
		</div>
		<div class="flex justify-end">
			<button type="submit"
				class="w-full rounded-md px-1 py-2 mt-6 bg-secondary text-white hover:bg-secondary-light hover:transition-all">
				Submit
			</button>
		</div>
	</form>
</div>