import { AbstractControl, ValidatorFn } from '@angular/forms';

export default class PasswordValidation {
  static match(controlName: string, checkControlName: string): ValidatorFn {
    return (controls: AbstractControl) => {
      const control = controls.get(controlName);
      const checkControl = controls.get(checkControlName);

      if (checkControl?.errors && !checkControl.errors['matched']) {
        return null;
      }

      if (control?.value !== checkControl?.value) {
        controls.get(checkControlName)?.setErrors({ matched: true });
        return { matched: true };
      } else {
        return null;
      }
    };
  }
}
