import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsComponent } from './settings/settings.component';
import { RouterModule } from '@angular/router';
import { SettingRoutingModule } from './setting-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextareaModule } from 'primeng/inputtextarea';

// CK Editor
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { PageHeaderComponent } from 'src/app/components/page-header/page-header.component';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
	declarations: [SettingsComponent],
	imports: [
		CommonModule,
		RouterModule.forChild(SettingRoutingModule),
		FormsModule,
		ReactiveFormsModule,
		PageHeaderComponent,
		InputTextareaModule,
		ButtonModule,
		TooltipModule,
		CKEditorModule,
	],
})
export class SettingsModule {}
