import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-upvote',
  templateUrl: './upvote.component.html',
  styleUrls: ['./upvote.component.scss'],
})
export class UpvoteComponent {
  @Input('upvotes') upvotes: string = '';
  @Input('isUpvoted') isUpvoted: boolean | number = false;
}
