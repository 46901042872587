import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';
import { Api } from 'src/app/const/api';
import { Pages } from 'src/app/enums/pages.enum';
import { HttpService } from 'src/app/services/http/http.service';
import PasswordValidation from 'src/app/services/password-validator';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
	selector: 'app-reset-password',
	standalone: true,
	imports: [
		CommonModule,
		ReactiveFormsModule,
		ButtonModule,
		InputTextModule,
		RouterModule,
		DividerModule,
	],
	templateUrl: './reset-password.component.html',
	styleUrl: './reset-password.component.scss',
})
export class ResetPasswordComponent {
	form: FormGroup;
	verificationParams: any;
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private utilityService: UtilityService,
		private http: HttpService,
	) {
		this.route.queryParams.subscribe((params: any) => {
			this.verificationParams = params;
		});
	}

	ngOnInit() {
		this.form = new FormGroup(
			{
				password: new FormControl('', [Validators.required, Validators.minLength(8)]),
				confirm_password: new FormControl('', [Validators.required]),
			},
			{
				validators: [PasswordValidation.match('password', 'confirm_password')],
			},
		);
	}

	get password() {
		return this.form.get('password');
	}
	get confirmPassword() {
		return this.form.get('confirm_password');
	}

	setNewPassword() {
		if (this.form.valid) {
			this.utilityService.showLoading();
			this.http
				.post(
					`${Api.NEW_PASSWORD}?email=${this.verificationParams.email}&code=${this.verificationParams.code}&id=${this.verificationParams.id}&reset=true`,
					{ password: this.form.value.password },
				)
				.subscribe({
					next: (res: any) => {
						this.utilityService.hideLoading();
						this.utilityService.showSuccessToast(res.data);
						this.router.navigate([Pages.SIgnIn], {
							replaceUrl: true,
						});
					},
					error: (err: any) => {
						this.utilityService.hideLoading();
					},
				});
		} else {
			this.form.markAllAsTouched();
			this.utilityService.showErrorToast('Required fields are missing.');
		}
	}
}
