import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Api } from 'src/app/const/api';
import { CkEditorImageUploadAdapter } from 'src/app/services/CkEditor-image-upload.adapter';
import { HttpService } from 'src/app/services/http/http.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
@Component({
	selector: 'app-edit-forum-post',
	templateUrl: './edit-forum-post.component.html',
	styleUrls: ['./edit-forum-post.component.scss'],
})
export class EditForumPostComponent {
	editPostForm: FormGroup;
	editor = ClassicEditor;
	editorConfig: any = {
		placeholder: 'Write something about your topic...',
		// plugins: ['MediaEmbed'],
		toolbar: {
			items: [
				'undo',
				'redo',
				'|',
				'bold',
				'italic',
				'fontsize',
				'|',
				'uploadImage',
				'|',
				'mediaEmbed',
				'link',
			],
		},
		mediaEmbed: {
			previewsInData: true,
		},
		link: {
			addTargetToExternalLinks: true,
		},
	};
	topicId: string = '';
	postData: any;

	constructor(
		private ref: DynamicDialogRef,
		private dialogConfig: DynamicDialogConfig,
		private http: HttpService,
		private utilityService: UtilityService,
	) {
		const { postData, topicId } = this.dialogConfig.data;
		this.postData = postData;
		this.topicId = topicId;
		this.editPostForm = new FormGroup({
			post_content: new FormControl('', [Validators.required]),
		});

		if (postData) {
			this.editPostForm.patchValue({
				post_content: postData.post_content,
			});
		}
	}

	get postContent() {
		return this.editPostForm.get('post_content');
	}

	savePost() {
		if (this.editPostForm.valid) {
			const params = {
				forum_id: this.topicId,
				post_content: this.editPostForm.value.post_content,
			};

			this.http.patch(`${Api.TOPIC_POST}/${this.postData.id}`, params).subscribe({
				next: (res: any) => {
					this.ref.close(this.editPostForm.value.post_content);
					this.utilityService.showSuccessToast('Post updated.');
				},
				error: (err: any) => {
					console.log(err);
					this.utilityService.showErrorToast('Unable to update post.');
				},
			});
		} else {
			this.utilityService.showErrorToast('Required field is invalid.');
		}
	}

	onReady(event: any) {
		event.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
			return new CkEditorImageUploadAdapter(loader);
		};
	}
}
