import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';

@Pipe({
  name: 'daysAgo',
})
export class DaysAgoPipe implements PipeTransform {
  constructor() {
    dayjs.extend(relativeTime);
  }
  transform(value: string): string {
    return dayjs().to(dayjs(value));
  }
}
