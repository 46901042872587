<p-table #table dataKey="id" [columns]="columns" [value]="data" [tableStyle]="{ 'min-width': '50rem' }"
	[paginator]="paginator" [rows]="rows" [rowsPerPageOptions]="[10, 25, 50]" [selectionMode]="'single'"
	rowGroupMode="subheader"
	styleClass="bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
	<ng-template pTemplate="header" let-columns>
		<!-- <tr class="flex justify-end">
			<app-icon-button [icon]="'pi-download'" (click)="exportCSV(table)" pTooltip="Export CSV"></app-icon-button>
		</tr> -->
		<tr>
			<th *ngFor="let col of columns"
				[ngStyle]="{'width': col.width, 'max-width': col.width, 'min-width': col.width}"
				[ngClass]="{'text-left': !col.isNumeric, 'text-right': col.isNumeric}" [pSortableColumn]="col.field"
				[pSortableColumnDisabled]="col.disableSort">
				{{ col.header }}
				<p-sortIcon [field]="col.field" *ngIf="!col.disableSort"></p-sortIcon>
			</th>
		</tr>

		<tr *ngIf="showFilter">
			<th *ngFor="let col of columns"
				[ngStyle]="{'width': col.width, 'max-width': col.width, 'min-width': col.width}"
				[ngClass]="{'text-left': !col.isNumeric, 'text-right': col.isNumeric}">
				<p-columnFilter type="text" [field]="col.field"
					*ngIf="col.field != 'Action' && col.field != 'Photo' && col.field != 'Image'"></p-columnFilter>
			</th>
		</tr>
	</ng-template>
	<ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
		<tr (click)="onRowClick(rowData, rowIndex)">
			<ng-container *ngFor="let col of columns">
				<td class="flex gap-1 items-center" *ngIf="showActions && col.fieldType == fieldTypes.Action">
					<div class="flex gap-1">
						<button pButton pRipple type="button" pTooltip="Edit" icon="pi pi-pencil"
							(click)="$event.stopPropagation(); onEdit(rowData, rowIndex)"
							class="p-button-rounded p-button-text" *ngIf="showEditAction"></button>

						<button pButton pRipple type="button" pTooltip="Delete" icon="pi pi-trash"
							(click)="$event.stopPropagation(); onDelete(rowData, rowIndex)"
							class="p-button-rounded p-button-text" *ngIf="showDeleteAction || rowData.showDeleteAction"
							[disabled]="rowData.IsReadOnly"></button>

						<button pButton pRipple type="button" [pTooltip]="extraActionToolTip" [icon]="extraActionIcon"
							(click)="$event.stopPropagation(); onExtraAction(rowData, rowIndex)"
							class="p-button-rounded p-button-text" *ngIf="showExtraAction"></button>
					</div>
				</td>
				<td [ngStyle]="{'width': col.width, 'max-width': col.width, 'min-width': col.width}"
					[ngClass]="{'text-left': !col.isNumeric, 'text-right': col.isNumeric}" *ngIf="col.fieldType == fieldTypes.Text ||
					col.fieldType == fieldTypes.Email" [class]="'cursor-pointer ' + col.class"
					[pTooltip]="col.showToolTip ? rowData[col.field]: ''" [autoHide]="false" tooltipPosition="bottom">
					{{ rowData[col.field]}}
				</td>
				<td [ngStyle]="{'width': col.width, 'max-width': col.width, 'min-width': col.width}"
					[ngClass]="{'text-left': !col.isNumeric, 'text-right': col.isNumeric}" *ngIf="
					col.fieldType == fieldTypes.Telephone" [class]="'cursor-pointer ' + col.class"
					[pTooltip]="col.showToolTip ? rowData[col.field]: ''" [autoHide]="false" tooltipPosition="bottom">
					{{ rowData[col.field] | phoneNumber}}
				</td>
				<td [ngStyle]="{'width': col.width, 'max-width': col.width, 'min-width': col.width}"
					[ngClass]="{'text-left': !col.isNumeric, 'text-right': col.isNumeric}"
					*ngIf="col.fieldType == fieldTypes.Date" [class]="'cursor-pointer'">
					{{ rowData[col.field] | date: ((col.dateFormat) ? dateFormat : 'MM/dd/yyyy')}}
				</td>
				<td [ngStyle]="{'width': col.width, 'max-width': col.width, 'min-width': col.width}"
					[ngClass]="{'text-left': !col.isNumeric, 'text-right': col.isNumeric}"
					*ngIf="col.fieldType == fieldTypes.Number" [class]="'cursor-pointer'">
					{{col?.prefix}}{{ (rowData[col.field] ? rowData[col.field]: 0) | number: '1.2-2'}}{{col?.suffix}}
				</td>
				<td [ngStyle]="{'width': col.width, 'max-width': col.width, 'min-width': col.width}"
					[ngClass]="{'text-left': !col.isNumeric, 'text-right': col.isNumeric}"
					*ngIf="col.field == 'Photo' || col.field == 'Image'" class="py-2">
					<img [src]="rowData[col.field]" class="rounded-xl h-[35px] w-[35px] cursor-pointer">
				</td>
				<td [ngStyle]="{'width': col.width, 'max-width': col.width, 'min-width': col.width}"
					[ngClass]="{'text-left': !col.isNumeric, 'text-right': col.isNumeric}"
					*ngIf="col.fieldType == fieldTypes.Boolean" [class]="'cursor-pointer'">
					{{ rowData[col.field] ? col.trueValue : col.falseValue}}
				</td>
				<td class="cursor-pointer text-blue-500"
					[ngStyle]="{'width': col.width, 'max-width': col.width, 'min-width': col.width}"
					[ngClass]="{'text-left': !col.isNumeric, 'text-right': col.isNumeric}"
					*ngIf="col.fieldType == fieldTypes.LinkButton" [class]="'cursor-pointer'">
					<span (click)="col.onClick()">
						{{col.header}}
					</span>
				</td>
				<td class="cursor-pointer"
					[ngStyle]="{'width': col.width, 'max-width': col.width, 'min-width': col.width}"
					[ngClass]="{'text-left': !col.isNumeric, 'text-right': col.isNumeric}"
					*ngIf="col.fieldType == fieldTypes.Image" [class]="'cursor-pointer'">
					<img [src]="col.imageBaseUrl + rowData[col.field]" [alt]=""
						class="h-14 w-14 max-h-14 max-w-14 rounded-md my-2" />
				</td>
			</ng-container>
		</tr>
	</ng-template>
</p-table>