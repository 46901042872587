import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Api } from 'src/app/const/api';
import { FieldsType } from 'src/app/enums/fields-type.enum';
import { HttpService } from 'src/app/services/http/http.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-product-details',
	templateUrl: './product-details.component.html',
	styleUrl: './product-details.component.scss',
})
export class ProductDetailsComponent {
	productId: string;
	productData: any;
	imageUrl: string = environment.imageUrl;
	columns = [
		{
			field: 'variant_name',
			header: 'Seeds Per pack',
			disableSort: true,
			fieldType: FieldsType.Text,
			imageBaseUrl: '',
			prefix: '',
			suffix: '',
			isEditable: false,
		},
		{
			field: 'variant_quantity',
			header: 'Available Quantity',
			disableSort: true,
			fieldType: FieldsType.Text,
		},
		{
			field: 'variant_unit_cost',
			header: 'Unit Cost',
			disableSort: true,
			prefix: '$',
			fieldType: FieldsType.Number,
		},
		{
			field: 'product_price',
			header: 'Price',
			prefix: '$',
			disableSort: true,
			fieldType: FieldsType.Number,
		},
		{
			field: 'product_discount_price',
			header: 'Discount Price',
			prefix: '$',
			disableSort: true,
			fieldType: FieldsType.Number,
		},
		{
			field: 'product_discount_percentage',
			header: 'Discount Percentage',
			prefix: '',
			suffix: '%',
			disableSort: true,
			fieldType: FieldsType.Number,
		},
		{
			field: 'variant_weight_gram',
			header: 'Weight(G)',
			prefix: '',
			suffix: '',
			disableSort: true,
			fieldType: FieldsType.Number,
		},
		{
			field: 'variant_weight_oz',
			header: 'Weight(OZ)',
			prefix: '',
			suffix: '',
			disableSort: true,
			fieldType: FieldsType.Number,
		},
	];
	constructor(
		private utilityService: UtilityService,
		private http: HttpService,
		private route: ActivatedRoute,
	) {
		this.route.params.subscribe((params) => {
			this.productId = params['id'];
		});
	}

	ngOnInit(): void {
		this.getProductDetails();
	}

	getProductDetails() {
		this.utilityService.showLoading();
		this.http.get(`${Api.PRODUCT_DETAILS}/${this.productId}`).subscribe({
			next: (res: any) => {
				console.log(res);
				this.productData = res;
				this.productData.product_images = this.productData.product_images.split(',');
				this.utilityService.hideLoading();
			},
			error: (err) => {
				this.utilityService.hideLoading();
			},
			complete: () => {
				this.utilityService.hideLoading();
			},
		});
	}
}
