import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductsComponent } from './products/products.component';
import { RouterModule } from '@angular/router';
import { ProductRoutingModule } from './product-routing.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { PageHeaderComponent } from 'src/app/components/page-header/page-header.component';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { SimpleTableComponent } from 'src/app/components/simple-table/simple-table.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { ImageModule } from 'primeng/image';
@NgModule({
	declarations: [ProductsComponent, ProductDetailsComponent],
	imports: [
		CommonModule,
		RouterModule.forChild(ProductRoutingModule),
		FormsModule,
		ReactiveFormsModule,
		NgxMaskModule.forRoot(),
		PageHeaderComponent,
		DropdownModule,
		ButtonModule,
		SimpleTableComponent,
		ImageModule,
	],
})
export class ProductModule {}
