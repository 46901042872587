import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { HttpService } from 'src/app/services/http/http.service';
import { UserService } from 'src/app/services/user/user.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CkEditorImageUploadAdapter } from 'src/app/services/CkEditor-image-upload.adapter';
import { ForumType } from '../../enum/forum-type.enum';
import { ForumLinkTo } from '../../enum/forum-link-to.enum';
import { Api } from 'src/app/const/api';
import { CreateForumTopicDialogType } from 'src/app/enums/create-topic-dialog-type.enum';

@Component({
	selector: 'app-add-topic',
	templateUrl: './add-topic.component.html',
	styleUrls: ['./add-topic.component.scss'],
})
export class AddTopicComponent {
	forumCategories: any[] = [];
	supplierProducts: any[] = [];

	topicForm: FormGroup;
	editor = ClassicEditor;
	editorConfig = {
		placeholder: 'Write something about your topic...',
		// plugins: ['MediaEmbed'],
		toolbar: {
			items: [
				'undo',
				'redo',
				'|',
				'bold',
				'italic',
				'fontsize',
				'|',
				'uploadImage',
				'|',
				'mediaEmbed',
				'link',
			],
		},
		mediaEmbed: {
			previewsInData: true,
		},
		link: {
			addTargetToExternalLinks: true,
		},
	};
	topicData: any;

	topicDialogType: CreateForumTopicDialogType = CreateForumTopicDialogType.General;
	forumType: ForumType = ForumType.General;

	constructor(
		public ref: DynamicDialogRef,
		public dialogConfig: DynamicDialogConfig,
		private http: HttpService,
		private utilityService: UtilityService,
	) {
		this.topicForm = new FormGroup({
			topic_title: new FormControl('', [Validators.required]),
			forum_category_id: new FormControl(''),
			topic_description: new FormControl('', [Validators.required]),
			topic_tags: new FormControl('', [Validators.required]),
			product: new FormControl(''),
		});

		const { topicData, forumType } = this.dialogConfig.data;
		this.forumType = forumType;
		this.topicData = topicData;
		if (topicData) {
			this._setValuesInFields();
		} else {
			this.getInitialData();
		}
	}

	get topicTitle() {
		return this.topicForm.get('topic_title');
	}
	get topicCategory() {
		return this.topicForm.get('forum_category_id');
	}
	get topicDescription() {
		return this.topicForm.get('topic_description');
	}
	get topicTags() {
		return this.topicForm.get('topic_tags');
	}
	get createForumTopicDialogType() {
		return CreateForumTopicDialogType;
	}

	async getInitialData() {
		if (this.forumType == ForumType.General) {
			await this.getForumCategories();
			this.topicDialogType = CreateForumTopicDialogType.General;
		} else {
			this.getProducts();
			this.topicDialogType = CreateForumTopicDialogType.Supplier;
		}
	}

	private async _setValuesInFields() {
		if (this.topicData) {
			const { topic_title, created_in: { created_id }, topic_description, topic_tags, created_in, link_to } = this.topicData;
			// NOTE:  created_id:  product id if created in forum otherwise category id.
			if (link_to == ForumLinkTo.None) {
				await this.getForumCategories();
				this.topicDialogType = CreateForumTopicDialogType.General;
				this.topicForm.patchValue({
					topic_title: topic_title,
					forum_category_id: created_id, // category id if created in general forum.
					topic_description: topic_description,
					topic_tags: topic_tags.split(','),
					product: ""
				});
			} else {
				await this.getProducts();
				this.topicDialogType = CreateForumTopicDialogType.Supplier;
				this.topicForm.patchValue({
					topic_title: topic_title,
					forum_category_id: "",
					topic_description: topic_description,
					topic_tags: topic_tags.split(','),
					product: created_id,
				});
			}
		}
	}

	getForumCategories() {
		return new Promise((resolve, reject) => {
			this.http.get(Api.GET_FORUM_CATEGORIES).subscribe({
				next: (res: any) => {
					this.forumCategories = res;
					resolve(true);
				},
				error: (err) => {
					console.log(err);
					reject(false);
				},
			});
		});
	}

	getProducts() {
		return new Promise((resolve, reject) => {
			this.utilityService.showLoading();
			this.http.get(`${Api.PRODUCTS}`).subscribe({
				next: (res: any) => {
					this.supplierProducts = res;
					this.supplierProducts.unshift({
						id: '00000000-0000-0000-0000-000000000000',
						product_name: 'General Discussion',
					});
					resolve(true);
				},
				error: (err) => {
					this.utilityService.hideLoading();
					console.log(err);
					reject(false);
				},
				complete: () => {
					this.utilityService.hideLoading();
					resolve(true);
				},
			});
		});
	}

	saveTopic() {
		if (this.topicData) {
			this.updateTopic();
		} else {
			this.addTopic();
		}
	}

	addTopic() {
		if (this.topicForm.valid) {
			const { product, topic_tags } = this.topicForm.value;
			const linkTo = product ? ForumLinkTo.Product : ForumLinkTo.None;
			const linkToId = product ?? '';
			const params = {
				...this.topicForm.value,
				type: ForumType.Supplier,
				topic_tags: topic_tags.toString(),
				link_to: linkTo,
				link_to_id: linkToId,
				created_by: 'SUPPLIER',
			};
			delete params.product;
			this.http.post(Api.TOPIC, params).subscribe({
				next: async (res: any) => {
					this.utilityService.showSuccessToast('Your topic has been added.');
					this.ref.close(true);
				},
				error: (err: any) => {
					this.utilityService.showErrorToast(err.error.message);
					this.utilityService.hideLoading();
				},
			});
		} else {
			this.topicForm.markAllAsTouched();
			this.utilityService.showErrorToast('Required fields are missing.');
		}
	}

	onReady(event: any) {
		event.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
			return new CkEditorImageUploadAdapter(loader);
		};
	}

	updateTopic() {
		this.utilityService.showLoading();
		const { product, topic_tags } = this.topicForm.value;
		const linkTo = product ? ForumLinkTo.Product : ForumLinkTo.None;
		const linkToId = product ?? '';
		const params = {
			...this.topicForm.value,
			type: ForumType.Supplier,
			topic_tags: topic_tags.toString(),
			link_to: linkTo,
			link_to_id: linkToId,
		};
		delete params.product;
		this.http.patch(`${Api.TOPIC}/${this.topicData.id}`, params).subscribe({
			next: (res: any) => {
				this.utilityService.hideLoading();
				this.utilityService.showSuccessToast('Topic updated.');
				this.ref.close(true);
			},
			error: (err) => {
				this.utilityService.hideLoading();
				this.utilityService.showErrorToast('Unable to update topic.');
			},
		});
	}
}
