import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';

@Component({
	standalone: true,
	selector: 'app-side-menu',
	templateUrl: './side-menu.component.html',
	styleUrls: ['./side-menu.component.scss'],
	imports: [RouterModule],
})
export class SideMenuComponent {
	constructor(private router: Router) {}

	signOut() {
		sessionStorage.clear();
		localStorage.clear();
		this.router.navigate([''], {
			replaceUrl: true,
		});
	}
}
