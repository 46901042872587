<div class="page">
	<app-page-header header="Products"></app-page-header>
	<div class="space-y-4">
		<div class="p-4 rounded shadow-md border border-[#c4c4c4]">
			<p class="font-semibold text-lg mb-4">Filters</p>
			<div class="flex gap-1">
				<p-dropdown [options]="categories" [(ngModel)]="filterParams.category_id" placeholder="Select Category"
					optionLabel="category_name" optionValue="id" [showClear]="false" placeholder="Select Category"
					(onClear)="clearFilter()" styleClass="w-[350px]" (onChange)="getProducts()"></p-dropdown>
				<!-- <button type="button" class="w-auto" label="Apply" pButton (click)="getProducts()"
					[disabled]="!filterParams.category_id"></button> -->
			</div>
		</div>

		<div class="">
			<app-simple-table [columns]="columns" [data]="products" [paginator]="true" [showFilter]="false"
				[showActions]="true" [showEditAction]="false" [showDeleteAction]="false"
				[onRowClick]="getProductDetails">
			</app-simple-table>
		</div>
	</div>
</div>