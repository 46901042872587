import { Component, OnInit, ViewChild } from '@angular/core';
import { Api } from 'src/app/const/api';
import { CommonApiCallService } from 'src/app/services/common-api-call/common-api-call.service';
import { HttpService } from 'src/app/services/http/http.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import * as dayjs from 'dayjs';
import { ExportService } from 'src/app/services/export/export.service';
import { FieldsType } from 'src/app/enums/fields-type.enum';
import { Pages } from 'src/app/enums/pages.enum';
import { Router } from '@angular/router';
@Component({
	standalone: false,
	selector: 'app-sales',
	templateUrl: './sales.component.html',
	styleUrls: ['./sales.component.scss'],
})
export class SalesComponent implements OnInit {
	_filterParamsCore = {
		date_range: '',
		category_id: '',
	};

	filterParams = { ...this._filterParamsCore };

	salesColumns: string[] = [
		'#',
		'created_at',
		'brand_name',
		'category_name',
		'product_sku',
		'product_name',
		'product_variant',
		'quantity',
		'price',
		'order_id',
	];

	sales: any[] = [];
	categories: any = [];
	brands: any = [];

	columns = [
		{
			field: 'created_at',
			header: 'Date & Time',
			disableSort: false,
			fieldType: FieldsType.Date,
			dateFormat: 'MMM, dd YYYY hh:mm',
		},
		{
			field: 'brand_name',
			header: 'Brand',
			disableSort: false,
			fieldType: FieldsType.Text,
			imageBaseUrl: '',
			prefix: '',
			suffix: '',
			isEditable: true,
		},
		{
			field: 'category_name',
			header: 'Category',
			disableSort: false,
			fieldType: FieldsType.Text,
		},
		{
			field: 'product_sku',
			header: 'Product SKU',
			disableSort: false,
			fieldType: FieldsType.Text,
		},
		{
			field: 'product_name',
			header: 'Product',
			prefix: '',
			disableSort: false,
			fieldType: FieldsType.Text,
		},
		{
			field: 'variant_name',
			header: 'Seeds/pack',
			prefix: '',
			disableSort: false,
			fieldType: FieldsType.Text,
		},
		{
			field: 'quantity',
			header: 'Quantity',
			prefix: '',
			disableSort: false,
			fieldType: FieldsType.Text,
		},
		{
			field: 'price',
			header: 'Price',
			prefix: '$',
			disableSort: false,
			fieldType: FieldsType.Number,
		},
		{
			field: 'product_discount_price',
			header: 'Discounted price',
			prefix: '$',
			disableSort: false,
			fieldType: FieldsType.Number,
		},
		{
			field: 'display_id',
			header: 'Order#',
			prefix: '',
			disableSort: false,
			fieldType: FieldsType.Text,
		},
	];

	constructor(
		private utilityService: UtilityService,
		private http: HttpService,
		private exportService: ExportService,
		private commonApiCall: CommonApiCallService,
		private router: Router,
	) {}

	ngOnInit(): void {
		this.getSales();
		this.getCategories();
	}

	getSales() {
		const { date_range, category_id } = this.filterParams;
		const startDateParsed = date_range[0] ? dayjs(date_range[0]).format('YYYY-MM-DD') : '';
		const endDateParsed = date_range[1] ? dayjs(date_range[1]).format('YYYY-MM-DD') : '';
		this.http
			.get(
				`${Api.SALES}?category=${category_id}&start=${startDateParsed}&end=${endDateParsed}`,
			)
			.subscribe({
				next: (res: any) => {
					this.sales = res;
				},
				error: (err) => {
					console.log(err);
				},
				complete: () => {
					this.utilityService.hideLoading();
				},
			});
	}

	getCategories() {
		this.commonApiCall.getCategories().subscribe({
			next: (res) => {
				this.categories = res;
			},
			error: (err) => {
				console.log(err);
			},
			complete: () => {
				this.utilityService.hideLoading();
			},
		});
	}

	exportOrders() {
		const csvColumns = [
			'#',
			'Order Date',
			'Supplier',
			'Brand',
			'Category',
			'SKU',
			'Product',
			'Seeds Per Pack',
			'Quantity',
			'Price',
			'Order ID',
		];
		let csvData = [];
		let dataToExport = [];
		dataToExport = this.sales;
		let index = 0;
		for (const data of dataToExport) {
			index++;
			csvData.push({
				'#': index,
				'Order Date': dayjs(data.created_at).format('MMMM DD, YYYY hh:mm a'),
				Supplier: data.supplier_name,
				Brand: data.brand_name,
				Category: data.category_name,
				SKU: data.product_sku,
				Product: data.product_name,
				'Seeds Per Pack': data.variant_name,
				Quantity: data.quantity,
				Price:
					data.price > data.product_discount_price
						? data.product_discount_price.toFixed(2)
						: data.price.toFixed(2),
				'Order ID': data.display_id,
			});
		}
		this.exportService.exportToCsv(
			csvData,
			`Sales-${dayjs().format('DD-MM-YYYY')}`,
			csvColumns,
		);
	}

	clearFilters() {
		this.filterParams = { ...this._filterParamsCore };
		this.getSales();
	}

	orderDetails = (orderData: any) => {
		this.router.navigate([Pages.Orders, orderData.order_id]);
	};
}
