<div class="w-auto mx-2">
	<div class="flex items-center mb-4 font-semibold cursor-pointer" (click)="back()">
		<span class="material-symbols-rounded">
			arrow_back_ios
		</span>
		Back to forum
	</div>
	<div class="bg-[#fff]" *ngIf="topicData">
		<div class="px-0 md:px-4 py-2 flex gap-2 md:gap-4 rounded">
			<img [src]="topicData.creator_data.creator_image" [alt]="topicData.creator_data.creator_name" width="40"
				 height="40" loading="lazy" fetchpriority="auto"
				 class="rounded-full h-5 w-5 sm:h-10 sm:w-10 object-cover"
				 onError="this.src = '/assets/images/placeholder-image.webp'">
			<div class="flex flex-col gap-2 w-full min-w-0">
				<div class="flex justify-between">
					<div class="flex flex-col text-sm">
						<h1 class="text-base font-medium">{{topicData.topic_title}}</h1>
						<p class="italic font-medium flex gap-2 font-sm">
							by {{ topicData.creator_data.creator_name }} in
							<span class="flex items-center gap-1 cursor-pointer w-fit not-italic">
								<div class="h-3 w-3 bg-[#c4c4c4]" [style.background]="topicData.created_in.color_code">
								</div>
								{{topicData.created_in.created_in}}
							</span>
						</p>
					</div>
					<span class="text-sm">{{topicData.created_at | date: "MMM dd, yyyy 'at' hh:mm a"}}</span>
				</div>
				<p class="text-sm" [innerHTML]="topicData.topic_description"></p>
				<div class="flex gap-2 flex-wrap">
					<ng-container *ngFor="let tag of topicData.topic_tags | commaSplitter">
						<span class="bg-[#4da31410] text-sm py-1 px-2 rounded capitalize">
							{{tag}}
						</span>
					</ng-container>
				</div>
				<div class="flex justify-between">
					<div class="flex gap-2">
						<app-like [likes]="topicData.likes" [isLiked]="topicData.liked == '1'"
								  (click)="topicData.liked == '1' ? unlikeTopic(topicData.id) : likeTopic(topicData.id)">
						</app-like>
						<button class="flex items-center gap-1 p-1 rounded text-sm hover:bg-[#4da31410] transition-all"
								(click)="toggleReply(topicData)" *ngIf="canCreateForum">
							<span class="material-symbols-rounded">
								reply
							</span>
							<span>Reply</span>
						</button>
					</div>
					<div class="flex gap-2" *ngIf="isModerator && !topicData.creator_data.is_tester">
						<button class="edit-button" (click)="editTopic(topicData)">
							<span class="material-symbols-rounded text-[20px]">edit</span>
						</button>
						<button class="delete-button" (click)="removeTopic(topicData)">
							<span class="material-symbols-rounded text-[20px]">delete</span>
						</button>
					</div>
				</div>

				<div *ngIf="topicData.enableReply" class="flex flex-col gap-2">
					<ckeditor [editor]="editor" [config]="editorConfig" [(ngModel)]="topicData.reply"
							  placeholder="Your reply" class="input-style w-full !p-0 h-full" (ready)="onReady($event)">
					</ckeditor>
					<button type="submit"
							class="w-fit rounded-md px-2 py-2 bg-secondary text-white hover:bg-secondary-light hover:transition-all self-end"
							(click)="replyOnTopic(topicData.id, topicData.reply)">
						Submit
					</button>
				</div>
			</div>
		</div>

		<div class="flex flex-col gap-2 md:pl-4 pt-4 border-t border-[#d4d4d4]">
			<ng-container *ngFor="let post of topicPosts; let i = index">
				<app-topic-post [data]="post" [index]="i" [onLike]="likePost" [onUnlike]="unlikePost"
								[reply]="replyOnPost" [showReplies]="showReplies" [hideReplies]="hideReplies"
								[onUpvote]="upvotePost" [onRemoveUpvote]="removeUpvote" [editPost]="editTopicPost"
								[removePost]="removeTopicPost" [isModerator]="isModerator" [canCreateForum]="canCreateForum"
								[editReply]="editPostReply" [removeReply]="removePostReply" [likeReply]="likeReply"
								[unLikeReply]="unLikeReply" [upvoteReply]="upvoteReply"
								[removeReplyUpvote]="removeReplyUpvote"></app-topic-post>
			</ng-container>
		</div>
	</div>
</div>